import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { IHomeCategory } from "src/app/models/interface/int-home-category";
import { IHomeType } from "src/app/models/interface/int-home-type";
import { HouseCategoryService } from "src/app/services/house-category.service";
import { HouseTypeService } from "src/app/services/house-type.service";
import { HouseVerificationService } from "src/app/services/house-verification.service";
import { PrintInvoiceService } from "src/app/services/print-invoice.service";
import { UtilService } from "src/app/services/util.service";

import { DecimalPipe } from "@angular/common";
import { MatDatepicker } from "@angular/material/datepicker";
import { Moment } from "moment";
import { lastValueFrom, Subject, takeUntil } from "rxjs";
import { MyDateFormats } from "src/app/helpers/providers/my-date-formats";
import { RESPONSE_STATUS } from "src/app/models/enum/response-status.enum";

@Component({
  selector: "app-global-confirm-dialog",
  templateUrl: "./global-confirm-dialog.component.html",
  styleUrls: ["./global-confirm-dialog.component.scss"],
  providers: [...MyDateFormats, DecimalPipe],
})
export class GlobalConfirmDialogComponent implements OnInit, OnDestroy {
  lang: string;

  filesSubmit: any[] = [];
  validFileType = "image/*, application/pdf";
  allowFileType = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];

  formDataGroup: FormGroup;
  checkHouseForm: FormGroup;

  houseCategory: IHomeCategory[] = [];
  selectedHouseCat: IHomeCategory;
  houseCatInputcontrol = new FormControl("");

  houseType: any[] = [];
  selectedHouseType: IHomeType;
  houseTypesInputControl = new FormControl("");

  /**
   * index
   * 0 - home
   * 1 - house category
   * 2 - house type
   */
  search: string[] = ["", "", ""];
  currentPageIndex: number[] = [1, 1, 1];
  currentPageLimit: number[] = [10, 10, 10];
  lastScrollTop: number[] = [0, 0, 0];
  isScrollTop: boolean[] = [false, false, false];
  isScrollDown: boolean[] = [true, true, true];
  scrollEnable: boolean[] = [true, true, true];

  maxDate = moment();
  readonly ICON_TYPE = ICON_TYPE;
  readonly CONFIRM_TYPE_ADVANCE: string = CONFIRM_TYPE.ADVANCE;
  readonly CONFIRM_TYPE_NORMAL: string = CONFIRM_TYPE.NORMAL;
  readonly CONFIRM_TYPE_APRV_CHECK_HOUSE: string =
    CONFIRM_TYPE.APRV_CHECK_HOUSE;

  private _destroyed = new Subject<void>();
  constructor(
    private formBuilder: FormBuilder,
    private printService: PrintInvoiceService,
    private homeService: HouseVerificationService,
    private util: UtilService,
    private translate: TranslateService,
    private decimalPipe: DecimalPipe,
    private dialogRef: MatDialogRef<GlobalConfirmDialogComponent>,
    private houseTypeService: HouseTypeService,
    private houseCategoryService: HouseCategoryService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang;
    });

    this.formDataGroup = this.formBuilder.group({
      date: [
        this.data.paid_date || "",
        this.data.is_reject && this.data.hasDate ? Validators.required : "",
      ],
      description: ["", this.data.is_reject ? Validators.required : ""],
    });

    this.checkHouseForm = this.formBuilder.group({
      home_type: ["", Validators.required],
      home_category: ["", Validators.required],
      number_of_rooms: [1, Validators.required],
      total_amount: [{ value: "-", disabled: true }, Validators.required],
      effective_date: [moment(), Validators.required],
    });

    this.checkHouseForm
      .get("number_of_rooms")
      .valueChanges.pipe(takeUntil(this._destroyed))
      .subscribe((res) => {
        let form = this.checkHouseForm.value;
        let home_type_amount = this.data.checkHouseData.home_type.amount;
        let monthlyFee = this.selectedHouseType.amount * res;
        monthlyFee = parseFloat(monthlyFee.toFixed(2));
        if (home_type_amount) {
          this.checkHouseForm
            .get("total_amount")
            .setValue(monthlyFee, {
              emit_value: false,
            });
        }
      });
  }

  ngOnInit(): void {
    if (this.lang == "" || !this.lang) {
      this.lang = this.util.getLang();
    }

    // In Case CONFIRM_TYPE_APRV_CHECK_HOUSE
    // -----------
    if (this.data.confirm_type == this.CONFIRM_TYPE_APRV_CHECK_HOUSE) {
      this.patchCheckHouseForm();
    }
  }

  onChangeFile(listFiles: any[]) {
    this.filesSubmit = listFiles;
  }

  onCloseDialog() {
    if (!this.data?.is_file && !this.data?.hasDate && this.data.is_reject) {
      this.dialogRef.close(
        this.formDataGroup.get("description")?.value || true
      );
    } else if (
      !this.data?.is_file &&
      this.data?.hasDate &&
      this.data.is_reject
    ) {
      let val = this.formDataGroup.value;
      let data = {
        desc: val.description,
        date: moment(val.date).format("YYYY-MM-DD"),
      };

      this.dialogRef.close(data || true);
    } else {
      if (this.data.confirm_type == this.CONFIRM_TYPE_APRV_CHECK_HOUSE) {
        let form = this.checkHouseForm.value;
        let dataJson = {
          home_type: form.home_type,
          number_of_rooms: form.number_of_rooms,
          effective_date: moment(form.effective_date)
            .startOf("month")
            .format("YYYY-MM-DD"),
          files: this.filesSubmit,
        };

        this.dialogRef.close(dataJson);
      } else {
        let data = {
          desc: this.formDataGroup.get("description")?.value,
          files: this.filesSubmit,
        };

        this.dialogRef.close(data);
      }
    }
  }

  async onPrintInvoice() {
    if (!this.data.payment) {
      this.util.openErrorSnackbar("message.payment_not_found");
      return;
    }

    // let createdAt = moment(this.data.payment.createdAt).subtract(1, "day").format("DD/MM/YYYY");
    // this.homeService
    //   .getTotalRemainingAmount(this.data.payment.home._id, createdAt)
    //   .subscribe((res) => {
    //     let printJson = {
    //       invoice_data: this.data.payment,
    //       remaining_amount: res.data,
    //       number_of_inv: 1,
    //       date_of_service: moment(this.data.home.invoice?.createdAt)
    //         .subtract(1, "day")
    //         .startOf("months")
    //         .locale("km")
    //         .format("MMMM YYYY"),
    //     };

    //     this.printService.printInvoiceSolidWaste(printJson);
    //   });

    const response = await lastValueFrom(this.homeService.printHomeReceipt(this.data.payment._id));
    if (response.status !== RESPONSE_STATUS.SUCCESS) return;

    this.printService.printPaidInvoiceSolidWaste(response.data);
  }

  getHouseCategories() {
    this.houseCategoryService
      .getAll(
        this.currentPageIndex[1],
        this.currentPageLimit[1],
        this.search[1],
        true
      )
      .subscribe((res) => {
        this.houseCategory.push(...res.data.data);
        this.scrollEnable[1] = res.data.data.length > 0;
      });
  }

  onHouseCatSearchChange(txtSearch: string) {
    this.houseCategory = [];
    this.selectedHouseCat = null;
    this.checkHouseForm.get("home_category").setValue("");
    this.checkHouseForm.get("home_type").setValue("");

    this.search[1] = txtSearch;
    this.currentPageIndex[1] = 1;
    this.getHouseCategories();
  }

  onScroll(event: any, type: string) {
    let offsetHeight = event.target.offsetHeight; // visible div height.
    let scrollTop = event.target.scrollTop; // the length that count from bottom of the visible div height to the top.
    let scrollHeight = event.target.scrollHeight; // overall height of the div.

    if (type == "house_cat") {
      this.isScrollTop[1] = scrollTop < this.lastScrollTop[1];
      this.isScrollDown[1] = scrollTop > this.lastScrollTop[1];
      this.lastScrollTop[1] = scrollTop;

      if (this.scrollEnable[1] && this.isScrollDown[1]) {
        if (scrollTop > 0 && offsetHeight + scrollTop >= scrollHeight * 0.7) {
          this.scrollEnable[1] = false;

          this.currentPageIndex[1]++;
          this.getHouseCategories();
        }
      }
    } else if (type == "house_type") {
      this.isScrollTop[2] = scrollTop < this.lastScrollTop[2];
      this.isScrollDown[2] = scrollTop > this.lastScrollTop[2];
      this.lastScrollTop[2] = scrollTop;

      if (this.scrollEnable[2] && this.isScrollDown[2]) {
        if (scrollTop > 0 && offsetHeight + scrollTop >= scrollHeight * 0.7) {
          this.scrollEnable[2] = false;

          this.currentPageIndex[2]++;
          this.getHouseTypes();
        }
      }
    }
  }

  getHouseTypes() {
    this.houseTypeService
      .getAll(
        this.currentPageIndex[2],
        this.currentPageLimit[2],
        this.search[2],
        this.selectedHouseCat._id,
        true
      )
      .subscribe((res) => {
        this.houseType.push(...res.data.data);
        this.scrollEnable[2] = res.data.data.length > 0;
      });
  }

  getHomeCatName(home: IHomeCategory) {
    if (this.lang == "en") return home.code + " - " + home.name_en;
    else return home.code + " - " + home.name_kh;
  }

  onChangeHouseCategory(house: IHomeCategory) {
    this.selectedHouseCat = house;
    this.checkHouseForm.get("home_type").setValue("");

    this.search[2] = "";
    this.houseType = [];
    this.currentPageIndex[2] = 1;
    this.selectedHouseType = null;
    this.houseTypesInputControl.setValue("");
    this.getHouseTypes();
  }

  onHouseTypeSearchChange(txtSearch: string) {
    this.houseType = [];
    this.selectedHouseType = null;
    this.checkHouseForm.get("home_type").setValue("");
    this.checkHouseForm.get("home_type").updateValueAndValidity();

    this.search[2] = txtSearch;
    this.currentPageIndex[2] = 1;
    this.getAllHouseTypesWithoutHouseCategory();
  }

  getAllHouseTypesWithoutHouseCategory() {
    this.houseTypeService
      .getAll(this.currentPageIndex[2], 10, this.search[2], undefined, true)
      .subscribe((res) => {
        this.houseType.push(...res.data.data);
        this.scrollEnable[2] = res.data.data.length > 0;
      });
  }

  onSelectHomeType() {
    this.checkHouseForm
      .get("home_category")
      .setValue(this.selectedHouseCat._id);
    let monthlyFee = this.selectedHouseType.amount * this.checkHouseForm.get('number_of_rooms').value;
    this.checkHouseForm
      .get("total_amount")
      .setValue(
        this.decimalPipe.transform(monthlyFee, "1.0-2")
      );
  }

  getHomeTypeName(home: IHomeType) {
    if (this.lang == "en")
      return (
        (home?.code || "-") +
        " - " +
        (home?.amount || "-") +
        " - " +
        (home?.name_en || "-")
      );
    else
      return (
        (home?.code || "-") +
        " - " +
        (home?.amount || "-") +
        " - " +
        (home?.name_kh || "-")
      );
  }

  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const dateValue = moment(this.checkHouseForm.get("effective_date").value!);
    dateValue.month(moment(normalizedMonthAndYear).month());
    dateValue.year(moment(normalizedMonthAndYear).year());
    this.checkHouseForm.get("effective_date").setValue(dateValue);
    datepicker.close();
  }

  patchCheckHouseForm() {
    const data = this.data.checkHouseData;
    this.selectedHouseCat = data?.home_type?.home_category;
    this.selectedHouseType = data?.home_type;

    this.currentPageLimit[1] = 100;
    this.currentPageLimit[2] = 100;

    this.checkHouseForm.patchValue({
      home_type: data.home_type._id,
      home_category: data.home_type.home_category._id,
      number_of_rooms: data.number_of_rooms,
      total_amount: parseFloat(data.home_type.total.toFixed(2)),
      effective_date: data.effective_date,
    });

    this.getHouseCategories();
    this.getHouseTypes();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }
}

export enum CONFIRM_TYPE {
  ADVANCE = "ADVANCE",
  NORMAL = "NORMAL",
  APRV_CHECK_HOUSE = "APPROVE_CHECKING_HOUSE",
}

export enum ICON_TYPE {
  Success,
  Info,
  Question,
  Warning,
  Error,
}
