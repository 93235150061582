import { PERMISSION_ENUM } from "./permissions.enum";

export const ROUTE_PERMISSIONS = {
  STAFF: [
    PERMISSION_ENUM.STAFF_CREATE,
    PERMISSION_ENUM.STAFF_UPDATE,
    PERMISSION_ENUM.STAFF_DELETE,
    PERMISSION_ENUM.STAFF_READ,
  ],
  USER: [
    PERMISSION_ENUM.USER_CREATE,
    PERMISSION_ENUM.USER_UPDATE,
    PERMISSION_ENUM.USER_DELETE,
    PERMISSION_ENUM.USER_READ,
  ],
  ROLE: [
    PERMISSION_ENUM.ROLE_CREATE,
    PERMISSION_ENUM.ROLE_UPDATE,
    PERMISSION_ENUM.ROLE_DELETE,
    PERMISSION_ENUM.ROLE_READ,
  ],
  PERMISSION: [
    PERMISSION_ENUM.PERMISSION_CREATE,
    PERMISSION_ENUM.PERMISSION_UPDATE,
    PERMISSION_ENUM.PERMISSION_DELETE,
    PERMISSION_ENUM.PERMISSION_READ,
  ],
  POST_SOLID_WASTE: [
    PERMISSION_ENUM.POST_SOLID_WASTE_CREATE,
    PERMISSION_ENUM.POST_SOLID_WASTE_UPDATE,
    PERMISSION_ENUM.POST_SOLID_WASTE_DELETE,
    PERMISSION_ENUM.POST_SOLID_WASTE_READ,
    PERMISSION_ENUM.POST_SOLID_WASTE_APPROVE,
  ],
  WAST_COLLECTION_HISTORY: [
    PERMISSION_ENUM.WAST_COLLECTION_HISTORY_CREATE,
    PERMISSION_ENUM.WAST_COLLECTION_HISTORY_UPDATE,
    PERMISSION_ENUM.WAST_COLLECTION_HISTORY_DELETE,
    PERMISSION_ENUM.WAST_COLLECTION_HISTORY_READ,
  ],
  CHECKING_HOUSE: [
    PERMISSION_ENUM.CHECKING_HOUSE_CREATE,
    PERMISSION_ENUM.CHECKING_HOUSE_UPDATE,
    PERMISSION_ENUM.CHECKING_HOUSE_DELETE,
    PERMISSION_ENUM.CHECKING_HOUSE_READ,
    PERMISSION_ENUM.CHECKING_HOUSE_VERIFYI,
    PERMISSION_ENUM.CHECKING_HOUSE_VERIFYII,
    PERMISSION_ENUM.CHECKING_HOUSE_APPROVE,
    // PERMISSION_ENUM.CHECKING_HOUSE_CREATE_AUTO_APPROVED,
    PERMISSION_ENUM.CHECKING_HOUSE_CREATE_BY_EXCEL,
  ],
  RECYCLE: [
    PERMISSION_ENUM.RECYCLE_CREATE,
    PERMISSION_ENUM.RECYCLE_UPDATE,
    PERMISSION_ENUM.RECYCLE_DELETE,
    PERMISSION_ENUM.RECYCLE_READ,
    PERMISSION_ENUM.RECYCLE_APPROVE,
  ],
  INVOICE_SOLID_WASTE: [
    PERMISSION_ENUM.INVOICE_SOLID_WASTE_CREATE,
    PERMISSION_ENUM.INVOICE_SOLID_WASTE_UPDATE,
    PERMISSION_ENUM.INVOICE_SOLID_WASTE_DELETE,
    PERMISSION_ENUM.INVOICE_SOLID_WASTE_READ,
  ],
  HOME: [
    PERMISSION_ENUM.HOME_CREATE,
    PERMISSION_ENUM.HOME_UPDATE,
    PERMISSION_ENUM.HOME_DELETE,
    PERMISSION_ENUM.HOME_READ,
    PERMISSION_ENUM.INVOICE_ADJUST_BY_EXCEL,
    PERMISSION_ENUM.HOME_VERIFY_BY_EXCEL,
  ],
  REQUEST: [
    PERMISSION_ENUM.REQUEST_CREATE,
    PERMISSION_ENUM.REQUEST_UPDATE,
    PERMISSION_ENUM.REQUEST_DELETE,
    PERMISSION_ENUM.REQUEST_READ,
    PERMISSION_ENUM.REQUEST_APPROVE,
  ],
  LOCATION: [
    PERMISSION_ENUM.LOCATION_CREATE,
    PERMISSION_ENUM.LOCATION_UPDATE,
    PERMISSION_ENUM.LOCATION_DELETE,
    PERMISSION_ENUM.LOCATION_READ,
  ],
  STREET: [
    PERMISSION_ENUM.STREET_CREATE,
    PERMISSION_ENUM.STREET_UPDATE,
    PERMISSION_ENUM.STREET_DELETE,
    PERMISSION_ENUM.STREET_READ,
  ],
  HOME_CATEGORY: [
    PERMISSION_ENUM.HOME_CATEGORY_CREATE,
    PERMISSION_ENUM.HOME_CATEGORY_UPDATE,
    PERMISSION_ENUM.HOME_CATEGORY_DELETE,
    PERMISSION_ENUM.HOME_CATEGORY_READ,
  ],
  HOME_TYPE: [
    PERMISSION_ENUM.HOME_TYPE_CREATE,
    PERMISSION_ENUM.HOME_TYPE_UPDATE,
    PERMISSION_ENUM.HOME_TYPE_DELETE,
    PERMISSION_ENUM.HOME_TYPE_READ,
  ],
  SERVICE_TYPE: [
    PERMISSION_ENUM.SERVICE_TYPE_CREATE,
    PERMISSION_ENUM.SERVICE_TYPE_UPDATE,
    PERMISSION_ENUM.SERVICE_TYPE_DELETE,
    PERMISSION_ENUM.SERVICE_TYPE_READ,
  ],
  INFORMATION: [
    PERMISSION_ENUM.INFORMATION_CREATE,
    PERMISSION_ENUM.INFORMATION_UPDATE,
    PERMISSION_ENUM.INFORMATION_DELETE,
    PERMISSION_ENUM.INFORMATION_READ,
  ],
  VIDEO_INFO: [
    PERMISSION_ENUM.VIDEO_INFO_CREATE,
    PERMISSION_ENUM.VIDEO_INFO_UPDATE,
    PERMISSION_ENUM.VIDEO_INFO_DELETE,
    PERMISSION_ENUM.VIDEO_INFO_READ,
  ],
  OPERATOR: [
    PERMISSION_ENUM.OPERATOR_CREATE,
    PERMISSION_ENUM.OPERATOR_UPDATE,
    PERMISSION_ENUM.OPERATOR_DELETE,
    PERMISSION_ENUM.OPERATOR_READ,
  ],
  WASTE: [
    PERMISSION_ENUM.WASTE_CREATE,
    PERMISSION_ENUM.WASTE_UPDATE,
    PERMISSION_ENUM.WASTE_DELETE,
    PERMISSION_ENUM.WASTE_READ,
  ],
  ITEM_RECYCLE: [
    PERMISSION_ENUM.ITEM_RECYCLE_CREATE,
    PERMISSION_ENUM.ITEM_RECYCLE_UPDATE,
    PERMISSION_ENUM.ITEM_RECYCLE_DELETE,
    PERMISSION_ENUM.ITEM_RECYCLE_READ,
  ],
  CONTACT_US: [
    PERMISSION_ENUM.CONTACT_US_CREATE,
    PERMISSION_ENUM.CONTACT_US_UPDATE,
    PERMISSION_ENUM.CONTACT_US_DELETE,
    PERMISSION_ENUM.CONTACT_US_READ,
  ],
  SUMMARY: [PERMISSION_ENUM.SUMMARY_READ],

  // SETTING: [
  //   PERMISSION_ENUM.PAYMENT_READ,
  //   PERMISSION_ENUM.PAYMENT_UPDATE,
  //   PERMISSION_ENUM.PAYMENT_CREATE,
  //   PERMISSION_ENUM.PAYMENT_DELETE,
  //   PERMISSION_ENUM.RECEIVE_CASH,
  // ],

  REPORT_PAYMENT: [
    PERMISSION_ENUM.READ_CASHIER_PAYMENT,
    // PERMISSION_ENUM.READ_CASHIERS_PAYMENT_SUMMARY,
    PERMISSION_ENUM.CASH_TRANSACTION_READ,
    PERMISSION_ENUM.CASH_TRANSACTION_DELETE,
    PERMISSION_ENUM.CASH_TRANSACTION_EXPORT,
    PERMISSION_ENUM.CASH_TRANSACTION_CREATE,
  ],

  PAYMENT: [
    PERMISSION_ENUM.PAYMENT_CREATE,
    PERMISSION_ENUM.RECEIVE_CASH,
    PERMISSION_ENUM.PAYMENT_READ,
    PERMISSION_ENUM.PAYMENT_UPDATE,
    PERMISSION_ENUM.PAYMENT_DELETE,
    PERMISSION_ENUM.PAYMENT_REFUND,
    PERMISSION_ENUM.PAYMENT_TRANSFER,
  ],

  REPORT: [
    // report_income
    // report_sales_debt
    PERMISSION_ENUM.REPORT_CHECKING_HOUSE_READ,
    PERMISSION_ENUM.REPORT_RECYCLE_READ,
    PERMISSION_ENUM.REPORT_BANK_PAYMENT_READ,
    PERMISSION_ENUM.REPORT_DAILY_SETTLEMENT_READ,
    PERMISSION_ENUM.REPORT_NEW_INVOICE_READ,
    PERMISSION_ENUM.REPORT_ADJUSTMENT_READ,
    PERMISSION_ENUM.REPORT_REFUND_AND_TRANSFER_READ,
    PERMISSION_ENUM.REPORT_DELETED_INVOICE_READ,
    PERMISSION_ENUM.REPORT_DEBT_INVOICE_READ,
    PERMISSION_ENUM.REPORT_NEW_HOME_READ,
    // report_house_payment
    PERMISSION_ENUM.READ_INVOICE_BY_MONTH,
    PERMISSION_ENUM.READ_PAYMENT_SUMMARY,
    PERMISSION_ENUM.READ_DEBT_BY_MONTH,
    PERMISSION_ENUM.READ_AGING_INVOICE,
  ],
};
