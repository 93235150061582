import { ICommonResponse } from '../models/response/common-response';
import { IHome } from '../models/interface/int-home';
import { IResponseWithPagination } from '../models/response/response-with-pagination';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RequestService } from './request.service';
import { IHomeHistory } from '../models/interface/int-home-history';
import { SubmitFailService } from './submit-fail.service';
import { UPDATE_TYPE } from '../modules/house-verification/components/house-form-dialog/house-form-dialog.component';
import { REQUEST_TYPE_ENUM } from '../models/enum/request-type.enum';
import { IPrintInvoiceLog } from '../models/interface/int-print-invoce-log';

export enum HOUSE_ENTPOINT {
	EXPORT_ALL_HOUSE = '/back-office/homes/as-excel',
	EXPORT_ALL_HOUSE_AS_CSV = '/back-office/homes/as-csv'
}

@Injectable({
	providedIn: 'root'
})
export class HouseVerificationService extends SubmitFailService {

	eventEmitHouseInfo = new EventEmitter();
	constructor(
		private requestService: RequestService
	) { super(); }


	/**
	 *
	 * @param page
	 * @param limit
	 * @param user
	 * @param location
	 * @param street
	 * @param status
	 * @param home_type
	 * @param home_category
	 * @param is_parent
	 * @returns
	 */
	getAll(data: any, loading: boolean = true): Observable<IResponseWithPagination<IHome>> {
		const url = environment.baseUrl + '/back-office/homes';
		return this.requestService.getJSON(url, data, loading);
	}

	getByID(id: string): Observable<ICommonResponse<IHome>> {
		const url = environment.baseUrl + '/back-office/homes/' + id;
		return this.requestService.getJSON(url);
	}

	getByEdcCustomerNo(edc_no: string): Observable<ICommonResponse<IHome>> {
		const url = environment.baseUrl + '/back-office/homes/edc_customer_number/' + edc_no;
		return this.requestService.getJSON(url, {}, true, false);
	}

	getTracking(id: string, page: number = 1, limit: number = 100, type: string = REQUEST_TYPE_ENUM.HOME_TYPE): Observable<IResponseWithPagination<IHomeHistory>> {
		const url = environment.baseUrl + '/back-office/homes/' + id + '/home-histories';
		return this.requestService.getJSON<any>(url, { page, limit });
	}

	checkExistingHome(file: any, keep_order: boolean = false): Observable<any> {
		const url = environment.baseUrl + '/back-office/homes/check-existing-homes-from-excel';
		const formData = new FormData();
		formData.append('file', file);
		formData.append('keep_order', keep_order.toString());

		return this.requestService.postJSON<any>(url, formData, false);
	}

	validateHomefromExcel(file: any): Observable<any> {
		const url = environment.baseUrl + '/back-office/homes/validate-homes-from-excel';
		const formData = new FormData();
		formData.append('file', file);

		return this.requestService.postJSON<any>(url, formData, false);
	}

	importHomefromExcel(file: any): Observable<any> {
		const url = environment.baseUrl + '/back-office/homes/import-homes-from-excel';
		const formData = new FormData();
		formData.append('file', file);

		return this.requestService.postJSON<any>(url, formData);
	}

	validateHomeTypefromExcel(file: any): Observable<any> {
		const url = environment.baseUrl + '/back-office/homes/validate-update-home-type';
		const formData = new FormData();
		formData.append('file', file);

		return this.requestService.postJSON<any>(url, formData, false);
	}

	submitHomeTypefromExcel(file: any): Observable<any> {
		const url = environment.baseUrl + '/back-office/homes/update-home-type';
		const formData = new FormData();
		formData.append('file', file);

		return this.requestService.postJSON<any>(url, formData, false);
	}




	// get remaining unpaid balance
	getRemainingBalance(h_id: string): Observable<ICommonResponse<Array<{ currency: string, total_amount: number, total_paid_amount: number }>>> {
		const url = environment.baseUrl + '/back-office/invoices/solid-wastes/homes/' + h_id + '/amount';
		return this.requestService.getJSON<any>(url);
	}

	// get total remaining amount both prepaid and paid payment
	getTotalRemainingAmount(h_id: string, date?: string): Observable<ICommonResponse<Array<any>>> {
		const url = environment.baseUrl + '/back-office/invoices/solid-wastes/' + h_id + '/total-remaining-amount';
		return this.requestService.getJSON<any>(url, {date});
	}

  printHomeReceipt(payment_id: string): Observable<any> {
    const url = environment.baseUrl + '/back-office/invoices/solid-wastes/home-print-receipt';
    return this.requestService.getJSON<any>(url, {payment_id});
  }

	// getHomeCategory(): Observable<HomeCategoryResponse> {
	//   const url = environment.baseUrl + '/back-office/home-categories/';
	//   return this.requestService.getJSON<HomeCategoryResponse>(url);
	// }

	// getHomeType(): Observable<any> {
	//   const url = environment.baseUrl + '/back-office/home-types/';
	//   return this.requestService.getJSON<any>(url);
	// }

	create(data: any, files?: any): Observable<ICommonResponse<IHome>> {
		const url = environment.baseUrl + '/back-office/homes/create';
		const formData = new FormData();

		for (let item in data) {
      if (item == 'phones') {
        for (let [index, phone] of data.phones.entries()) {
          formData.append(`phones[${index}]`, phone);
        }

      } else {
        if (data[item]) formData.append(item, data[item]);
      }
		}

		if (files) {
			for (let [index, file] of files.entries()) {
				formData.append(`files[${index}][file]`, file.files);
				formData.append(`files[${index}][type]`, file.type);
			}
		}

		return this.requestService.postJSON(url, formData);
	}

	createMinimalHome(data: any): Observable<ICommonResponse<IHome>> {
		const url = environment.baseUrl + '/back-office/homes/create-minimal';
		const formData = new FormData();

		for (let item in data) {
			if (data[item]) formData.append(item, data[item]);
		}

		return this.requestService.postJSON(url, formData);
	}

	update(id: string, data: any, files?: any): Observable<ICommonResponse<IHome>> {
		const url = environment.baseUrl + '/back-office/homes/' + id + '/update';
		const formData = new FormData();

		if (data.type == UPDATE_TYPE.REFERENCE) {
			for (let item in data) {
				if (item != 'files') {
					if (data[item]) formData.append(item, data[item]);
				}
			}

			for (let [index, file] of files.entries()) {
				formData.append(`files[${index}][file]`, file.files);
				formData.append(`files[${index}][type]`, file.type);
			}

		} else {
			for (let item in data) {
        if (item == 'phones') {
          for (let [index, phone] of data.phones.entries()) {
            formData.append(`phones[${index}]`, phone);
          }

        } else {
          if (data[item]) formData.append(item, data[item]);
        }
			}
		}

		return this.requestService.patchJSON(url, formData);
	}

	deleteFileHome(home_id: string, file_id: string): Observable<any> {
		const url = environment.baseUrl + '/back-office/homes/' + home_id + '/file/' + file_id;
		return this.requestService.delete<any>(url);
	}

	verify(id: string, status: number): Observable<ICommonResponse<IHome>> {
		const url = environment.baseUrl + '/back-office/homes/' + id + '/verify';
		return this.requestService.putJSON(url, { status });
	}

	changeStatus(id: string, status: boolean): Observable<ICommonResponse<IHome>> {
		const url = environment.baseUrl + '/back-office/homes/' + id + '/verify';
		return this.requestService.putJSON(url, { status });
	}

	disableHome(id: string, type: string, files?: any): Observable<ICommonResponse<IHome>> {
		const url = environment.baseUrl + '/back-office/homes/' + id + '/update';
		const formData = new FormData();
		formData.append('type', type);

		if (files) {
			for (let [index, file] of files.entries()) {
				formData.append(`files[${index}][file]`, file.files);
				formData.append(`files[${index}][type]`, file.type);
			}
		}

		return this.requestService.patchJSON(url, formData);
	}

	enableHome(id: string, type: string): Observable<ICommonResponse<IHome>> {
		const url = environment.baseUrl + '/back-office/homes/' + id + '/update';
		const formData = new FormData();
		formData.append('type', type);

		return this.requestService.patchJSON(url, formData);
	}
	getHomeInvoices(data: any, loading: boolean = true): Observable<IResponseWithPagination<IHome>> {
		const url = environment.baseUrl + '/back-office/invoices/solid-wastes/home-invoice';
		return this.requestService.getJSON(url, data, loading);
	}

	getPrintInvoiceLogs(data: any, loading: boolean = true): Observable<IResponseWithPagination<IPrintInvoiceLog>> {
		const url = environment.baseUrl + '/back-office/invoices/solid-wastes/print-invoice-log';
		return this.requestService.getJSON(url, data, loading);
	}

	getHomeInvoicesPrintUrl(data: any){
		let url = new URL(environment.baseUrl + '/back-office/invoices/solid-wastes/home-invoice-print/invoices-text');
		Object.keys(data).forEach(k =>{
			if(data[k]){
				url.searchParams.append(k, data[k]);
			}
		})
		return url.toString();
	}

  getHomeDetailInvoice(data: any): Observable<any> {
    const url = environment.baseUrl + '/back-office/invoices/solid-wastes/home-invoice-print/invoices-text';
		return this.requestService.getJSON(url, data);
  }
}
