import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { PERMISSION_ENUM } from 'src/app/models/enum/permissions.enum';
import { ROUTE_PERMISSIONS } from 'src/app/models/enum/route-permission';
import { ElectronService } from 'src/app/services/electron.service';
import { UtilService } from 'src/app/services/util.service';
import { IMenuItem } from '../../models/interface/int-menu-item';

@Component({
	selector: 'app-side-menu-left',
	templateUrl: './side-menu-left.component.html',
	styleUrls: ['./side-menu-left.component.scss'],
	animations: [
		trigger('subItem', [
			state('void', style({ opacity: 0, transform: 'translateX(-50px)' })),
			transition('void => *', animate('.2s ease')),
			transition('* => void', animate('.1s ease-out')),
		]),
	],
})
export class SideMenuLeftComponent implements OnInit {
	@Input() public hideText = false;
	@Input() public phone = false;
	@Input() public hideMenuBar = false;
	@Input() public currentRole: string;
	@Output() valueChange = new EventEmitter();

	permissions: string[];
	currentUserRole: string;

	menuItems: IMenuItem[] = [];
	displayMenuItems: IMenuItem[] = [];

	constructor(
		private translate: TranslateService,
		private util: UtilService,
		private electronService: ElectronService
	) {
		translate.onLangChange.subscribe((event: LangChangeEvent) => {
			event.lang = this.util.getLang();
			this.getMenuItems();
		});

		this.permissions = this.util.getPermissions();
	}

	ngOnInit(): void {
		this.currentUserRole = this.currentRole;
		let lang = this.util.getLang();
		this.translate.use(lang ? lang : 'km');
		this.getMenuItems();
	}

	/**
	 * Filter menu to be displayed
	 * @param menuItems
	 */
	private traverseMenuItems(menuItems: IMenuItem[]) {
		return menuItems.filter((item) => {
			if (item.children) {
				item.children = this.traverseMenuItems(item.children);
			}

			if (!item.roles) {
				return true;
			}

			if (!this.currentUserRole) {
				return true;
			}

			return item.roles.includes(this.currentUserRole);
		});
	}

	menuClick(item: IMenuItem) {
		if (!item.routerLink) {
			item.expanded = !item.expanded;
		}
	}

	hideNavBar() {
		this.hideMenuBar = true;
		this.valueChange.emit(this.hideMenuBar);
	}

	getMenuItems() {
		this.translate.get('root').subscribe(res => {
			this.menuItems = [
				{
					title: res.menu_left.dashboard,
					routerLink: '/dashboard',
					svgIcon: 'menu_dashboard',
					isNoPermission: ROUTE_PERMISSIONS.SUMMARY.filter(p => this.permissions?.includes(p)).length === 0 || this.electronService.isElectron
				},
				{
					title: res.menu_left.verify_house,
					routerLink: '/house-verify',
					svgIcon: 'menu_house_verify',
					isNoPermission: ROUTE_PERMISSIONS.HOME.filter(p => this.permissions?.includes(p)).length === 0 || this.electronService.isElectron
				},
				{
					title: res.menu_left.request_register_house,
					routerLink: '/request',
					icon: 'app_registration',
					isNoPermission: ROUTE_PERMISSIONS.REQUEST.filter(p => this.permissions?.includes(p)).length === 0 || this.electronService.isElectron
				},
				{
					title: res.menu_left.post_checking_house,
					routerLink: '/invoice/checking-house',
					svgIcon: 'menu_checking_house',
					isNoPermission: ROUTE_PERMISSIONS.CHECKING_HOUSE.filter(p => this.permissions?.includes(p)).length === 0 || this.electronService.isElectron
				},
				{
					title: res.menu_left.post_solid_waste,
					routerLink: '/post-solidwaste',
					svgIcon: 'menu_post_solid_waste',
					isNoPermission: ROUTE_PERMISSIONS.POST_SOLID_WASTE.filter(p => this.permissions?.includes(p)).length === 0 || this.electronService.isElectron
				},
				{
					title: res.menu_left.post_recycle,
					routerLink: '/post-recycle',
					svgIcon: 'menu_post_recycle',
					isNoPermission: ROUTE_PERMISSIONS.RECYCLE.filter(p => this.permissions?.includes(p)).length === 0 || this.electronService.isElectron
				},
				{
					title: res.menu_left.waste_collection_history,
					routerLink: '/report/waste-collection',
					svgIcon: 'menu_waste_collection_history',
					isNoPermission: ROUTE_PERMISSIONS.WAST_COLLECTION_HISTORY.filter(p => this.permissions?.includes(p)).length === 0 || this.electronService.isElectron
				},
				{
					title: res.menu_left.waste_service_fee,
					svgIcon: 'menu_fees',
					exact: true,
					expanded: false,
					isNoPermission: [...ROUTE_PERMISSIONS.HOME_CATEGORY, ...ROUTE_PERMISSIONS.HOME_TYPE].filter(p => this.permissions?.includes(p)).length === 0 || this.electronService.isElectron,
					children: [
						{
							title: res.menu_left.house_category,
							routerLink: '/house-category-management',
							icon: 'villa',
							isNoPermission: ROUTE_PERMISSIONS.HOME_CATEGORY.filter(p => this.permissions?.includes(p)).length === 0,
						},
						{
							title: res.menu_left.house_type,
							routerLink: '/house-type-management',
							icon: 'settings_ethernet',
							isNoPermission: ROUTE_PERMISSIONS.HOME_TYPE.filter(p => this.permissions?.includes(p)).length === 0,
						},
						// {
						// 	title: res.menu_left.other_service,
						// 	routerLink: '/service-type',
						// 	icon: 'format_list_numbered_rtl',
						// 	isNoPermission: ROUTE_PERMISSIONS.SERVICE_TYPE.filter(p => this.permissions?.includes(p)).length === 0,
						// }
						// {
						//   title: res.menu_left.house_category_house_type,
						//   routerLink: '/house-category-house-type',
						//   icon: 'table_rows',
						// },
					],
				},
				{
					title: res.menu_left.invoice,
					svgIcon: 'menu_receipt',
					exact: true,
					expanded: false,
					isNoPermission: [
						...ROUTE_PERMISSIONS.INVOICE_SOLID_WASTE,
						PERMISSION_ENUM.READ_INVOICE_BY_MONTH,
						PERMISSION_ENUM.READ_DEBT_BY_MONTH,
						PERMISSION_ENUM.READ_AGING_INVOICE
					].filter(p => this.permissions?.includes(p)).length === 0,
					children: [
						{
							title: res.menu_left.invoice_solid_waste,
							routerLink: '/invoice/solid-waste',
							icon: 'scatter_plot',
							isNoPermission: ROUTE_PERMISSIONS.INVOICE_SOLID_WASTE.filter(p => this.permissions?.includes(p)).length === 0,
						},
						{
							title: res.menu_left.print_invoice,
							routerLink: '/home-invoice',
							icon: 'print',
							isNoPermission: [PERMISSION_ENUM.INVOICE_SOLID_WASTE_READ].filter(p => this.permissions?.includes(p)).length === 0 || !this.electronService.isElectron,
						},
						{
							title: res.menu_left.print_invoice_from_excel,
							routerLink: '/home-invoice-by-upload',
							icon: 'print',
							isNoPermission: [PERMISSION_ENUM.INVOICE_SOLID_WASTE_READ].filter(p => this.permissions?.includes(p)).length === 0 || !this.electronService.isElectron,
						}
					]
				},
				{
					title: res.menu_left.report,
					svgIcon: 'menu_report',
					exact: true,
					expanded: false,
					isNoPermission: [...ROUTE_PERMISSIONS.REPORT].filter(p => this.permissions?.includes(p)).length === 0,
					children: [
						{
							title: res.menu_left.report_income,
							routerLink: '/report/income',
							svgIcon: 'report_annual_income',
							// isNoPermission: [PERMISSION_ENUM.READ_INVOICE_BY_MONTH].filter(p => this.permissions?.includes(p)).length === 0,
						},
						{
							title: res.menu_left.report_sales_debt,
							routerLink: '/report/sales-debt',
							svgIcon: 'report_sales_debt',
							// isNoPermission: [PERMISSION_ENUM.REPORT_NEW_HOME_READ].filter(p => this.permissions?.includes(p)).length === 0,
						},
						// {
						// 	title: res.menu_left.invoice,
						// 	routerLink: '/report/invoice',
						// 	icon: '',
						// 	isNoPermission: false,
						// },
						// {
						// 	title: res.menu_left.request_register_house,
						// 	routerLink: '/report/request',
						// 	icon: '',
						// 	isNoPermission: false,
						// },
						{
							title: res.menu_left.report_post_checking_house,
							routerLink: '/report/fee/checking-house',
							svgIcon: 'report_request_update_house_type',
							isNoPermission: [PERMISSION_ENUM.REPORT_CHECKING_HOUSE_READ].filter(p => this.permissions?.includes(p)).length === 0,
						},
						// {
						// 	title: res.menu_left.post_solid_waste,
						// 	routerLink: '/report/post-solid-waste',
						// 	icon: '',
						// 	isNoPermission: false,
						// },
						{
							title: res.menu_left.report_post_recycle,
							routerLink: '/report/fee/recycle',
							svgIcon: 'report_request_recycle',
							isNoPermission: [PERMISSION_ENUM.REPORT_RECYCLE_READ].filter(p => this.permissions?.includes(p)).length === 0,
						},
						// {
						// 	title: res.menu_left.verify_house,
						// 	routerLink: '/report/house-verification',
						// 	icon: '',
						// 	isNoPermission: false,
						// },
						{
							title: res.menu_left.bank_payment,
							routerLink: '/report/bank-payment',
							svgIcon: 'report_bank_payment',
							isNoPermission: [PERMISSION_ENUM.REPORT_BANK_PAYMENT_READ].filter(p => this.permissions?.includes(p)).length === 0,
						},
						{
							title: res.menu_left.daily_settlement,
							routerLink: '/report/daily-settlement',
							svgIcon: 'report_daily_settlement',
							isNoPermission: [PERMISSION_ENUM.REPORT_DAILY_SETTLEMENT_READ].filter(p => this.permissions?.includes(p)).length === 0,
						},
						{
							title: res.menu_left.report_create_new_invoice,
							routerLink: '/report/create-new-invoice',
							svgIcon: 'report_create_new_invoice',
							isNoPermission: [PERMISSION_ENUM.REPORT_NEW_INVOICE_READ].filter(p => this.permissions?.includes(p)).length === 0,
						},
						{
							title: res.menu_left.report_amount_adjustment,
							routerLink: '/report/amount-adjustment',
							svgIcon: 'report_adjustment_amount',
							isNoPermission: [PERMISSION_ENUM.REPORT_ADJUSTMENT_READ].filter(p => this.permissions?.includes(p)).length === 0,
						},
						{
							title: res.menu_left.report_refund_transfer,
							routerLink: '/report/refund-and-transfer',
							svgIcon: 'report_refund_transfer',
							isNoPermission: [PERMISSION_ENUM.REPORT_REFUND_AND_TRANSFER_READ].filter(p => this.permissions?.includes(p)).length === 0,
						},
						{
							title: res.menu_left.report_delete_invoice,
							routerLink: '/report/delete-invoice',
							svgIcon: 'report_remove_invoice',
							isNoPermission: [PERMISSION_ENUM.REPORT_DELETED_INVOICE_READ].filter(p => this.permissions?.includes(p)).length === 0,
						},
						{
							title: res.menu_left.detail_debt,
							routerLink: '/report/debt-detail',
							svgIcon: 'report_debt',
							isNoPermission: [PERMISSION_ENUM.REPORT_DEBT_INVOICE_READ].filter(p => this.permissions?.includes(p)).length === 0,
						},
						{
							title: res.menu_left.report_create_new_customer,
							routerLink: '/report/create-new-customer',
							svgIcon: 'report_new_user',
							isNoPermission: [PERMISSION_ENUM.REPORT_NEW_HOME_READ].filter(p => this.permissions?.includes(p)).length === 0,
						},
						{
							title: res.menu_left.payments,
							routerLink: '/report/house-payment',
							svgIcon: 'report_house_payment',
							// isNoPermission: [PERMISSION_ENUM.REPORT_NEW_HOME_READ].filter(p => this.permissions?.includes(p)).length === 0,
						},
						// {
						// 	title: res.menu_left.report_transfer_payment,
						// 	routerLink: '/report/transfer-payment',
						// 	icon: 'sync_alt',
						// 	isNoPermission: false,
						// },
						// {
						// 	title: res.menu_left.report_transaction,
						// 	routerLink: '/report/transaction',
						// 	icon: '',
						// 	isNoPermission: false,
						// },
						{
							title: res.menu_left.report_total_sales,
							routerLink: '/report-total-sales',
							svgIcon: 'report_total_sales',
							isNoPermission: [PERMISSION_ENUM.READ_INVOICE_BY_MONTH].filter(p => this.permissions?.includes(p)).length === 0,
						},
						{
							title: res.menu_left.report_payment_summary,
							routerLink: '/report-payment-summary',
							svgIcon: 'report_payment_summary',
							isNoPermission: [PERMISSION_ENUM.READ_PAYMENT_SUMMARY].filter(p => this.permissions?.includes(p)).length === 0,
						},
						{
							title: res.menu_left.report_total_debt,
							routerLink: '/report-total-debt',
							svgIcon: 'report_total_debt',
							isNoPermission: [PERMISSION_ENUM.READ_DEBT_BY_MONTH].filter(p => this.permissions?.includes(p)).length === 0,
						},
						{
							title: res.menu_left.report_total_aging,
							routerLink: '/report-total-aging',
							svgIcon: 'report_debt_by_age',
							isNoPermission: [PERMISSION_ENUM.READ_AGING_INVOICE].filter(p => this.permissions?.includes(p)).length === 0,
						},
						{
							title: res.menu_left.report_print_invoice,
							routerLink: '/report-print-invoice',
							svgIcon: 'report_print_invoice',
							isNoPermission: [PERMISSION_ENUM.INVOICE_SOLID_WASTE_READ].filter(p => this.permissions?.includes(p)).length === 0,
						}
					],
				},
				{
					title: res.menu_left.report_cash,
					svgIcon: 'report_cash_payment',
					exact: true,
					expanded: false,
					isNoPermission: [...ROUTE_PERMISSIONS.REPORT_PAYMENT].filter(p => this.permissions?.includes(p)).length === 0,
					children: [
						{
							title: res.menu_left.report_cash_detail,
							routerLink: '/report/payment-detail',
							svgIcon: 'report_cash_in_detail',
							isNoPermission: [PERMISSION_ENUM.READ_CASHIER_PAYMENT].filter(p => this.permissions?.includes(p)).length === 0,
						},
						// {
						// 	title: res.menu_left.report_cash_summary,
						// 	routerLink: '/report/payment-summary',
						// 	svgIcon: 'report_cash_in_summary',
						// 	isNoPermission: [PERMISSION_ENUM.READ_CASHIERS_PAYMENT_SUMMARY].filter(p => this.permissions?.includes(p)).length === 0,
						// },
						{
							title: res.menu_left.report_cash_transaction,
							routerLink: '/report/payment-transaction',
							svgIcon: 'report_cash_transaction',
							isNoPermission: [PERMISSION_ENUM.CASH_TRANSACTION_READ].filter(p => this.permissions?.includes(p)).length === 0,
						},
					]
				},
				{
					title: res.menu_left.operator_management,
					svgIcon: 'menu_operator',
					exact: true,
					expanded: false,
					isNoPermission: [...ROUTE_PERMISSIONS.OPERATOR].filter(p => this.permissions?.includes(p)).length === 0 || this.electronService.isElectron,
					children: [
						{
							title: res.menu_left.operators,
							routerLink: '/operator',
							svgIcon: 'menu_operator',
							isNoPermission: ROUTE_PERMISSIONS.OPERATOR.filter(p => this.permissions?.includes(p)).length === 0,
						},
						{
							title: res.menu_left.waste,
							routerLink: '/waste',
							svgIcon: 'menu_waste',
							isNoPermission: ROUTE_PERMISSIONS.WASTE.filter(p => this.permissions?.includes(p)).length === 0,
						},
					],
				},
				{
					title: res.menu_left.street,
					routerLink: '/street',
					svgIcon: 'menu_road',
					isNoPermission: ROUTE_PERMISSIONS.STREET.filter(p => this.permissions?.includes(p)).length === 0 || this.electronService.isElectron,
				},
				{
					title: res.menu_left.information,
					icon: 'newspaper',
					exact: true,
					expanded: false,
					isNoPermission: [...ROUTE_PERMISSIONS.INFORMATION, ...ROUTE_PERMISSIONS.VIDEO_INFO].filter(p => this.permissions?.includes(p)).length === 0 || this.electronService.isElectron,
					children: [
						{
							title: res.menu_left.video,
							routerLink: '/video',
							svgIcon: 'menu_video',
							isNoPermission: ROUTE_PERMISSIONS.VIDEO_INFO.filter(p => this.permissions?.includes(p)).length === 0,
						},
						{
							title: res.menu_left.information,
							routerLink: '/information',
							svgIcon: 'menu_post',
							isNoPermission: ROUTE_PERMISSIONS.INFORMATION.filter(p => this.permissions?.includes(p)).length === 0,
						},
					],
				},
				{
					title: res.menu_left.user,
					svgIcon: 'menu_manage_user',
					exact: true,
					expanded: false,
					isNoPermission: [...ROUTE_PERMISSIONS.STAFF, ...ROUTE_PERMISSIONS.USER, ...ROUTE_PERMISSIONS.ROLE].filter(p => this.permissions?.includes(p)).length === 0 || this.electronService.isElectron,
					children: [
						{
							title: res.menu_left.role,
							routerLink: '/role',
							icon: 'work',
							isNoPermission: ROUTE_PERMISSIONS.ROLE.filter(p => this.permissions?.includes(p)).length === 0,
						},
						// {
						// 	title: res.menu_left.permission,
						// 	routerLink: '/permission',
						// 	icon: 'work',
						// 	isNoPermission: false,
						// },
						{
							title: res.menu_left.staff,
							routerLink: '/staff',
							svgIcon: 'menu_staff',
							isNoPermission: ROUTE_PERMISSIONS.STAFF.filter(p => this.permissions?.includes(p)).length === 0,
						},
						{
							title: res.menu_left.user,
							routerLink: '/user',
							svgIcon: 'menu_user',
							isNoPermission: ROUTE_PERMISSIONS.USER.filter(p => this.permissions?.includes(p)).length === 0,
						},
					],
				},
				// {
				// 	title: res.menu_left.setting,
				// 	routerLink: '/setting',
				// 	icon: 'settings',
				// 	isNoPermission: ROUTE_PERMISSIONS.SETTING.filter(p => this.permissions?.includes(p)).length === 0,
				// },
				// {
				// 	title: res.menu_left.payments,
				// 	routerLink: '/payments',
				// 	icon: 'local_offer',
				// 	isNoPermission: false,
				// },
			];

			this.displayMenuItems = this.traverseMenuItems(this.menuItems);
		})
	}

}
