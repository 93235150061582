import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './modules/shared/shared.module';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth/auth.guard';

import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { RootComponent } from './components/root/root.component';
import { SideMenuLeftComponent } from './components/side-menu-left/side-menu-left.component';
import { TermConditionComponent } from './components/term-condition/term-condition.component';
import { DirectivesModule } from './directives/directives.module';
import { MatErrorComponent } from './helpers/class/mat-error-comp';
import { patchMatFormField } from './helpers/class/patch-mat-form-field';

import { APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular-ivy";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

patchMatFormField();
@NgModule({
  declarations: [
    AppComponent,
    RootComponent,
    SideMenuLeftComponent,
    MatErrorComponent,
    PrivacyComponent,
    TermConditionComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatMenuModule,
    MatSnackBarModule,
    NgxSpinnerModule,
    SharedModule,
    LayoutModule,
    DirectivesModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    GoogleMapsModule
  ],
  providers: [
    AuthGuard,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
