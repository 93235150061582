import { RootComponent } from './components/root/root.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { ROUTE_PERMISSIONS } from './models/enum/route-permission';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { PERMISSION_ENUM } from './models/enum/permissions.enum';
import { TermConditionComponent } from './components/term-condition/term-condition.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: RootComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        data: { animation: 'dashboard', permissions: ROUTE_PERMISSIONS.SUMMARY },
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((mod) => mod.DashboardModule),
      },
      {
        path: 'post-solidwaste',
        canActivate: [AuthGuard],
        data: { animation: 'postPending', permissions: ROUTE_PERMISSIONS.POST_SOLID_WASTE },
        loadChildren: () => import('./modules/post-solidwaste-pending/post-solidwaste-pending.module').then((mod) => mod.PostSolidwastePendingModule),
      },
      {
        path: 'post-recycle',
        canActivate: [AuthGuard],
        data: { animation: 'recyclePending', permissions: ROUTE_PERMISSIONS.RECYCLE },
        loadChildren: () => import('./modules/post-recycle-pending/post-recycle-pending.module').then((mod) => mod.PostRecyclePendingModule),
      },
      {
        path: 'invoice/solid-waste',
        canActivate: [AuthGuard],
        data: { animation: 'invoiceSolidWaste', permissions: ROUTE_PERMISSIONS.INVOICE_SOLID_WASTE },
        loadChildren: () => import('./modules/invoice-solid-waste/invoice-solid-waste.module').then((mod) => mod.InvoiceSolidWasteModule),
      },
      {
        path: 'invoice/checking-house',
        canActivate: [AuthGuard],
        data: { animation: 'checkHousePending', permissions: ROUTE_PERMISSIONS.CHECKING_HOUSE },
        loadChildren: () => import('./modules/invoice-checking-house-pending/invoice-checking-house-pending.module').then((mod) => mod.InvoiceCheckingHousePendingModule),
      },
      {
        path: 'report-total-sales',
        canActivate: [AuthGuard],
        data: { animation: 'report_total_sales', permissions: [PERMISSION_ENUM.READ_INVOICE_BY_MONTH] },
        loadChildren: () => import('./modules/report-total-sales/report-total-sales.module').then((mod) => mod.ReportTotalSalesModule),
      },
      {
        path: 'report-payment-summary',
        canActivate: [AuthGuard],
        data: { animation: 'report_payment_summary', permissions: [PERMISSION_ENUM.READ_INVOICE_BY_MONTH] },
        loadChildren: () => import('./modules/report-payment-summary/report-payment-summary.module').then((mod) => mod.ReportPaymentSummaryModule),
      },
      {
        path: 'report-total-debt',
        canActivate: [AuthGuard],
        data: { animation: 'report_total_debt', permissions: [PERMISSION_ENUM.READ_DEBT_BY_MONTH] },
        loadChildren: () => import('./modules/report-total-debt/report-total-debt.module').then((mod) => mod.ReportTotalDebtModule),
      },
      {
        path: 'report-total-aging',
        canActivate: [AuthGuard],
        data: { animation: 'report_total_aging', permissions: [PERMISSION_ENUM.READ_AGING_INVOICE] },
        loadChildren: () => import('./modules/report-total-aging/report-total-aging.module').then((mod) => mod.ReportTotalAgingModule),
      },
      {
        path: 'home-invoice',
        canActivate: [AuthGuard],
        data: { animation: 'report_invoice', permissions: [PERMISSION_ENUM.INVOICE_SOLID_WASTE_READ] },
        loadChildren: () => import('./modules/home-invoice/home-invoice.module').then((mod) => mod.HomeInvoiceModule),
      },
      {
        path: 'home-invoice-by-upload',
        canActivate: [AuthGuard],
        data: { animation: 'report_invoice', permissions: [PERMISSION_ENUM.INVOICE_SOLID_WASTE_READ] },
        loadChildren: () => import('./modules/home-invoice-by-upload/home-invoice-by-upload.module').then((mod) => mod.HomeInvoiceByUploadModule),
      },
      {
        path: 'report-print-invoice',
        canActivate: [AuthGuard],
        data: { animation: 'report_print_invoice', permissions: [PERMISSION_ENUM.INVOICE_SOLID_WASTE_READ] },
        loadChildren: () => import('./modules/print-invoice-log/print-invoice-log.module').then((mod) => mod.PrintInvoiceLogModule),
      },
      {
        path: 'report/fee/recycle',
        canActivate: [AuthGuard],
        data: { animation: 'feeRecycle', permissions: [...ROUTE_PERMISSIONS.RECYCLE, PERMISSION_ENUM.REPORT_RECYCLE_READ] },
        loadChildren: () => import('./modules/report-fee-recycle/report-fee-recycle.module').then((mod) => mod.ReportFeeRecycleModule),
      },
      {
        path: 'report/fee/checking-house',
        canActivate: [AuthGuard],
        data: { animation: 'feeCheckHouse', permissions: [...ROUTE_PERMISSIONS.CHECKING_HOUSE, PERMISSION_ENUM.REPORT_CHECKING_HOUSE_READ] },
        loadChildren: () => import('./modules/report-fee-checking-house/report-fee-checking-house.module').then((mod) => mod.ReportFeeCheckingHouseModule),
      },
      {
        path: 'report/bank-payment',
        canActivate: [AuthGuard],
        data: { animation: 'bankPayment', permissions: [...ROUTE_PERMISSIONS.INVOICE_SOLID_WASTE, ...ROUTE_PERMISSIONS.SUMMARY, PERMISSION_ENUM.REPORT_BANK_PAYMENT_READ] },
        loadChildren: () => import('./modules/report-bank-payment/report-bank-payment.module').then((mod) => mod.ReportBankPaymentModule),
      },
      {
        path: 'report/daily-settlement',
        canActivate: [AuthGuard],
        data: { animation: 'dailySettle', permissions: [...ROUTE_PERMISSIONS.INVOICE_SOLID_WASTE, ...ROUTE_PERMISSIONS.SUMMARY, PERMISSION_ENUM.REPORT_DAILY_SETTLEMENT_READ] },
        loadChildren: () => import('./modules/report-daily-settlement/report-daily-settlement.module').then((mod) => mod.ReportDailySettlementModule),
      },
      {
        path: 'report/waste-collection',
        canActivate: [AuthGuard],
        data: { animation: 'reportWaste', permissions: ROUTE_PERMISSIONS.WAST_COLLECTION_HISTORY },
        loadChildren: () => import('./modules/report-waste-collection/report-waste-collection.module').then((mod) => mod.ReportWasteCollectionModule),
      },
      {
        path: 'video',
        canActivate: [AuthGuard],
        data: { animation: 'video', permissions: ROUTE_PERMISSIONS.VIDEO_INFO },
        loadChildren: () => import('./modules/video-management/video-management.module').then((mod) => mod.VideoManagementModule),
      },
      {
        path: 'information',
        canActivate: [AuthGuard],
        data: { animation: 'information', permissions: ROUTE_PERMISSIONS.INFORMATION },
        loadChildren: () => import('./modules/information-management/information-management.module').then((mod) => mod.InformationManagementModule),
      },
      {
        path: 'staff',
        canActivate: [AuthGuard],
        data: { animation: 'staff', permissions: ROUTE_PERMISSIONS.STAFF },
        loadChildren: () => import('./modules/staff/staff.module').then((mod) => mod.StaffModule),
      },
      {
        path: 'operator',
        canActivate: [AuthGuard],
        data: { animation: 'operator', permissions: ROUTE_PERMISSIONS.OPERATOR },
        loadChildren: () => import('./modules/operator/operator.module').then((mod) => mod.OperatorModule),
      },
      {
        path: 'waste',
        canActivate: [AuthGuard],
        data: { animation: 'waste', permissions: ROUTE_PERMISSIONS.OPERATOR },
        loadChildren: () => import('./modules/waste/waste.module').then((mod) => mod.WasteModule),
      },
      {
        path: 'house-verify',
        canActivate: [AuthGuard],
        data: { animation: 'houseVerify', permissions: ROUTE_PERMISSIONS.HOME },
        loadChildren: () => import('./modules/house-verification/house-verification.module').then((mod) => mod.HouseVerificationModule),
      },
      {
        path: 'house-type-management',
        canActivate: [AuthGuard],
        data: { animation: 'houseType', permissions: ROUTE_PERMISSIONS.HOME_TYPE },
        loadChildren: () => import('./modules/house-type-management/house-type-management.module').then((mod) => mod.HouseTypeManagementModule),
      },
      {
        path: 'house-category-management',
        canActivate: [AuthGuard],
        data: { animation: 'houseCategory', permissions: ROUTE_PERMISSIONS.HOME_CATEGORY },
        loadChildren: () => import('./modules/house-category-management/house-category-management.module').then((mod) => mod.HouseCategoryManagementModule),
      },
      {
        path: 'service-type',
        canActivate: [AuthGuard],
        data: { animation: 'service_type', permissions: ROUTE_PERMISSIONS.SERVICE_TYPE },
        loadChildren: () => import('./modules/other-service-type/other-service-type.module').then((mod) => mod.OtherServiceTypeModule),
      },
      {
        path: 'street',
        canActivate: [AuthGuard],
        data: { animation: 'street', permissions: ROUTE_PERMISSIONS.STREET },
        loadChildren: () => import('./modules/street/street.module').then((mod) => mod.StreetModule),
      },
      {
        path: 'user',
        canActivate: [AuthGuard],
        data: { animation: 'user', permissions: ROUTE_PERMISSIONS.USER },
        loadChildren: () => import('./modules/user/user.module').then((mod) => mod.UserModule),
      },
      {
        path: 'permission',
        canActivate: [AuthGuard],
        data: { animation: 'permission', permissions: ROUTE_PERMISSIONS.PERMISSION },
        loadChildren: () => import('./modules/permission/permission.module').then((mod) => mod.PermissionModule),
      },
      {
        path: 'role',
        canActivate: [AuthGuard],
        data: { animation: 'role', permissions: ROUTE_PERMISSIONS.ROLE },
        loadChildren: () => import('./modules/role/role.module').then((mod) => mod.RoleModule),
      },
      {
        path: 'request',
        canActivate: [AuthGuard],
        data: { animation: 'request', permissions: ROUTE_PERMISSIONS.REQUEST },
        loadChildren: () => import('./modules/request-update-house/request-update-house.module').then((mod) => mod.RequestUpdateHouseModule),
      },
      {
        path: 'report/invoice',
        canActivate: [AuthGuard],
        data: { animation: 'report_invoice' },
        loadChildren: () => import('./modules/report-invoice/report-invoice.module').then((mod) => mod.ReportInvoiceModule),
      },
      {
        path: 'report/request',
        canActivate: [AuthGuard],
        data: { animation: 'report_request' },
        loadChildren: () => import('./modules/report-request/report-request.module').then((mod) => mod.ReportRequestModule),
      },
      {
        path: 'report/post-solid-waste',
        canActivate: [AuthGuard],
        data: { animation: 'report_post_solid_waste' },
        loadChildren: () => import('./modules/report-post-solid-waste/report-post-solid-waste.module').then((mod) => mod.ReportPostSolidWasteModule),
      },
      {
        path: 'report/house-verification',
        canActivate: [AuthGuard],
        data: { animation: 'report_house_verification' },
        loadChildren: () => import('./modules/report-house-verification/report-house-verification.module').then((mod) => mod.ReportHouseVerificationModule),
      },
      {
        path: 'report/debt-detail',
        canActivate: [AuthGuard],
        data: { animation: 'report_debt_detail', permissions: [PERMISSION_ENUM.REPORT_DEBT_INVOICE_READ] },
        loadChildren: () => import('./modules/report-dept-detail/report-dept-detail.module').then((mod) => mod.ReportDeptDetailModule),
      },
      {
        path: 'report/delete-invoice',
        canActivate: [AuthGuard],
        data: { animation: 'report_delete_invoice', permissions: [PERMISSION_ENUM.REPORT_DELETED_INVOICE_READ] },
        loadChildren: () => import('./modules/report-delete-invoice/report-delete-invoice.module').then((mod) => mod.ReportDeleteInvoiceModule),
      },
      {
        path: 'report/amount-adjustment',
        canActivate: [AuthGuard],
        data: { animation: 'report_report_adjustment', permissions: [PERMISSION_ENUM.REPORT_ADJUSTMENT_READ] },
        loadChildren: () => import('./modules/report-amount-adjustment/report-amount-adjustment.module').then((mod) => mod.ReportAmountAdjustmentModule),
      },
      {
        path: 'report/transaction',
        canActivate: [AuthGuard],
        data: { animation: 'report_transaction' },
        loadChildren: () => import('./modules/report-transaction/report-transaction.module').then((mod) => mod.ReportTransactionModule),
      },
      {
        path: 'report/refund-and-transfer',
        canActivate: [AuthGuard],
        data: { animation: 'report_refund_transfer', permissions: [PERMISSION_ENUM.REPORT_REFUND_AND_TRANSFER_READ] },
        loadChildren: () => import('./modules/report-refund-transfer/report-refund-transfer.module').then((mod) => mod.ReportRefundTransferModule),
      },
      {
        path: 'report/create-new-invoice',
        canActivate: [AuthGuard],
        data: { animation: 'creat_new_invoice', permissions: [PERMISSION_ENUM.REPORT_NEW_INVOICE_READ] },
        loadChildren: () => import('./modules/report-create-new-invoice/report-create-new-invoice.module').then((mod) => mod.ReportCreateNewInvoiceModule),
      },
      {
        path: 'report/create-new-customer',
        canActivate: [AuthGuard],
        data: { animation: 'creat_new_customer', permissions: [PERMISSION_ENUM.REPORT_NEW_HOME_READ] },
        loadChildren: () => import('./modules/report-create-new-house/report-create-new-house.module').then((mod) => mod.ReportCreateNewHouseModule),
      },
      {
        path: 'report/transfer-payment',
        canActivate: [AuthGuard],
        data: { animation: 'transfer_payment' },
        loadChildren: () => import('./modules/report-transfer-payment/report-transfer-payment.module').then((mod) => mod.ReportTransferPaymentModule),
      },
      {
        path: 'report/house-payment',
        canActivate: [AuthGuard],
        data: { animation: 'house_payment' },
        loadChildren: () => import('./modules/report-house-payment/report-house-payment.module').then((mod) => mod.ReportHousePaymentModule),
      },
      {
        path: 'report/income',
        canActivate: [AuthGuard],
        data: { animation: 'report_income' },
        loadChildren: () => import('./modules/report-income/report-income.module').then((mod) => mod.ReportIncomeModule),
      },
      {
        path: 'report/sales-debt',
        canActivate: [AuthGuard],
        data: { animation: 'report_sales_debt' },
        loadChildren: () => import('./modules/report-sales-debt/report-sales-debt.module').then((mod) => mod.ReportSalesDebtModule),
      },
      {
        path: 'report/payment-detail',
        canActivate: [AuthGuard],
        data: { animation: 'report_payment_detail' },
        loadChildren: () => import('./modules/report-cash-in-detail/report-cash-in-detail.module').then((mod) => mod.ReportCashInDetailModule),
      },
      // {
      //   path: 'report/payment-summary',
      //   canActivate: [AuthGuard],
      //   data: { animation: 'report_payment_summary' },
      //   loadChildren: () => import('./modules/report-cash-in-summary/report-cash-in-summary.module').then((mod) => mod.ReportCashInSummaryModule),
      // },
      {
        path: 'report/payment-transaction',
        canActivate: [AuthGuard],
        data: { animation: 'report_payment_transaction' },
        loadChildren: () => import('./modules/report-cash-transaction/report-cash-transaction.module').then((mod) => mod.ReportCashTransactionModule),
      },
      {
        path: 'setting',
        canActivate: [AuthGuard],
        data: { animation: 'settings', permissions: ROUTE_PERMISSIONS.STAFF },
        loadChildren: () => import('./modules/setting/setting.module').then((mod) => mod.SettingModule),
      },
      {
        path: 'payments',
        canActivate: [AuthGuard],
        data: { animation: 'payments', permissions: [] },
        loadChildren: () => import('./modules/payments/payments.module').then((mod) => mod.PaymentsModule),
      }
    ],
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'change-password',
    loadChildren: () => import('./modules/change-password/change-password.module').then((m) => m.ChangePasswordModule),
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'term-condition',
    component: TermConditionComponent,
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
