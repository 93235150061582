import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KhanOnlyPipe } from './khan-only.pipe';
import { MomentDatePipe } from './moment-date.pipe';
import { NumberFormatPipe } from './number-format.pipe';
import { SafePipe } from './safe.pipe';
import { UpdateHomeTypePipe } from './update-home-type.pipe';
import { UserNamePipe } from './user-name.pipe';
import { LocationPipe } from './location.pipe';
import { FormatHouseTypePipe } from './format-house-type.pipe';
import { RoleTranslatePipe } from './role-translate.pipe';
import { TranslateObjectPipe } from './translate-object.pipe';
import { LocalizeDatePipe } from './localize-date.pipe';
import { MonthLocalePipe } from './month-locale.pipe';
import { DisplayPhonesArrayPipe } from './display-phones-array.pipe';
import { AbsolutePipe } from './absolute.pipe';

@NgModule({
  declarations: [
    KhanOnlyPipe,
    MomentDatePipe,
    NumberFormatPipe,
    SafePipe,
    UpdateHomeTypePipe,
    UserNamePipe,
    LocationPipe,
    FormatHouseTypePipe,
    RoleTranslatePipe,
    TranslateObjectPipe,
    LocalizeDatePipe,
    MonthLocalePipe,
    DisplayPhonesArrayPipe,
    AbsolutePipe
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    KhanOnlyPipe,
    MomentDatePipe,
    NumberFormatPipe,
    SafePipe,
    UpdateHomeTypePipe,
    UserNamePipe,
    LocationPipe,
    FormatHouseTypePipe,
    RoleTranslatePipe,
    TranslateObjectPipe,
    LocalizeDatePipe,
    MonthLocalePipe,
    DisplayPhonesArrayPipe,
    AbsolutePipe
  ]
})
export class PipeModule { }
