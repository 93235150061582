import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import axios from 'axios';
import * as moment from 'moment';
import { lastValueFrom, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoadingService } from './loading.service';
import { UtilService } from './util.service';

const datePipe = new DatePipe('en-US');
const transDate = (val: Date, is_time: boolean = false) => {
	if (val) {
		if (is_time) return datePipe.transform(val, 'dd/MM/YYYY HH:mm a');
		else return datePipe.transform(val, 'dd/MM/YYYY');

	} else return '';
};

const cashFormat = (val: number): string | number => {
	if (val) return val.toFixed(2);
	else return 0;
};

const absolute = (val: number): number => {
  if (val) return Math.abs(val);
  else return 0;
};

const startDate = (val: Date) => moment(val).subtract(1, 'months').startOf('month').format('DD/MM/YYYY');
const endDate = (val: Date) => moment(val).subtract(1, 'months').endOf('month').format('DD/MM/YYYY');

@Injectable({
	providedIn: 'root'
})
export class PrintInvoiceService {
	cssReplacer: string = "<!-- [ CSS ] -->";
	domReplacer: string = "<!-- [ DOC ] -->";
	// reportStyle = ``;

	// doc: string = `
	// <!Doctype html>
	// <html>
	//   <head>
	//     <style>
	//         @font-face {
	//           font-family: 'barcode';
	//           src: url('/assets/fonts/barcode.ttf');
	// 				  }

	// 				  .bar-code-container {
	// 					  position: absolute !important;
	// 					  right: 0 !important;
	// 					  top: 62px !important;
	// 				  }

	// 				  .bar-code-container .text {
	// 					  text-align: right !important;
	// 					  font-size: larger !important;
	// 					  font-weight: bold !important;
	// 					  margin-bottom: 2px !important;
	// 				  }

	// 				  .bar-code-container .barcode-text {
	// 					  font-family: 'barcode', cursive !important;
	// 					  font-size: 64px !important;
	// 				  }

	// 				  .summary-table .bar-code-container {
	// 					  right: unset !important;
	// 					  left: 32px !important;
	// 					  top: 38px !important;
	// 				  }

	// 				  .summary-table .bar-code-container .text {
	// 					  text-align: left !important;
	// 					  font-size: larger !important;
	// 					  font-weight: bold !important;
	// 					  margin-bottom: 2px !important;
	// 				  }

	// 				  @font-face {
	// 					  font-family: 'khmerOSMoulLight';
	// 					  src: url('/assets/fonts/KHMEROSMUOLLIGHT.TTF');
	// 				  }

	// 				  @font-face {
	// 					  font-family: "KhmerOSbattambang";
	// 					  src: url("/assets/fonts/KhmerOSbattambang.ttf");
	// 				  }

	// 				  @font-face {
	// 					  font-family: "SegoeUI";
	// 					  src: url("/assets/fonts/segoeui.ttf");
	// 				  }

	// 				  * {
	// 					  box-sizing: border-box !important;
	// 				  }

	// 				  p {
	// 					  margin: 0 !important;
	// 				  }

	// 				  body {
	// 					  margin: 0 !important;
	// 					  font-family: "SegoeUI", "KhmerOSbattambang" !important;
	// 					  margin: 0 0.5in !important;
	// 				  }

	// 				  h2 {
	// 					  font-family: "SegoeUI", "KhmerOSbattambang" !important;
	// 				  }

	// 				  h3 {
	// 					  font-family: "SegoeUI", "KhmerOSbattambang" !important;
	// 				  }

	// 				  #header {
	// 					  position: relative !important;
	// 					  height: 140px !important;
	// 				  }

	// 				  .legends,
	// 				  #header {
	// 					  text-align: center !important;
	// 				  }

	// 				  .legends h4 {
	// 					  margin: 1rem 0 !important;
	// 				  }

	// 				  #header h2,
	// 				  .legends h2 {
	// 					  font-weight: 300 !important;
	// 					  font-family: 'khmerOSMoulLight' !important;
	// 					  line-height: 24px !important;
	// 					  color: #497242 !important;
	// 				  }

	// 				  .white {
	// 					  color: white !important;
	// 				  }

	// 				  .primary {
	// 					  color: #497242 !important;
	// 				  }

	// 				  .secondary {
	// 					  color: #86b17c !important;
	// 				  }

	// 				  .lighter-primary {
	// 					  color: #97bb8f !important;
	// 				  }

	// 				  .darker-primary {
	// 					  color: #6ea063 !important;
	// 				  }

	// 				  .greyish-color {
	// 					  color: #f0f5ef !important;
	// 				  }

	// 				  .dark-text {
	// 					  color: #3e6139 !important;
	// 				  }

	// 				  .bold {
	// 					  font-weight: bold !important;
	// 				  }

	// 				  .user-name {
	// 					  color: black !important;
	// 					  font-weight: bold !important;
	// 					  text-transform: uppercase !important;
	// 					  margin: 0 !important;
	// 					  font-size: larger !important;
	// 				  }

	// 				  .desc {
	// 					  color: black !important;
	// 					  text-transform: uppercase !important;
	// 					  margin: 0 !important;
	// 				  }

	// 				  table {
	// 					  width: 100% !important;
	// 				  }

	// 				  table {
	// 					  width: 100% !important;
	// 					  border-collapse: collapse !important;
	// 				  }

	// 				  /* table,
	// 				  th,
	// 				  td {
	// 					  border: 1px solid #000000 !important;
	// 					  font-family: 'khmerMEF1' !important;
	// 				  }

	// 				  th,
	// 				  td {
	// 					  padding: 2 8px !important;
	// 				  } */

	// 				  .cut-section {
	// 					  background: #95aa85 !important;
	// 					  border: 1px solid #95aa85 !important;
	// 				  }

	// 				  .table-right td {
	// 					  padding: 2px 10px !important;
	// 					  font-family: "SegoeUI", "KhmerOSbattambang" !important;
	// 				  }

	// 				  .tb-desc {
	// 					  text-align: right !important;
	// 					  vertical-align: bottom !important;
	// 					  font-size: larger !important;
	// 					  font-family: "SegoeUI", "KhmerOSbattambang" !important;
	// 				  }

	// 				  .data-table {
	// 					  position: relative !important;
	// 					  margin-bottom: 600px !important;
	// 				  }

	// 				  .data-table::after {
	// 					  content: '' !important;
	// 					  background-image: url('/assets/image/logo_1.png') !important;
	// 					  background-position: center !important;
	// 					  background-repeat: no-repeat !important;
	// 					  background-size: contain !important;
	// 					  position: absolute !important;
	// 					  top: 176% !important;
	// 					  left: 50% !important;
	// 					  transform: translate(-50%, -50%) !important;
	// 					  z-index: -1 !important;
	// 					  width: 700px !important;
	// 					  height: 700px !important;
	// 					  min-width: 600px !important;
	// 				  }

	// 				  .data-table th {
	// 					  padding: 10px 20px !important;
	// 					  font-family: "SegoeUI", "KhmerOSbattambang" !important;
	// 					  background-color: #86b17c !important;
	// 				  }

	// 				  .data-table td {
	// 					  padding: 10px 20px !important;
	// 					  font-family: "SegoeUI", "KhmerOSbattambang" !important;
	// 				  }

	// 				  .summary-table th,
	// 				  .summary-table td {
	// 					  padding: 10px 10px !important;
	// 					  border: 2px solid #56734b !important;
	// 					  border-collapse: collapse !important;
	// 				  }

	// 				  .summary-table td.label {
	// 					  border: 2px solid #f0f5ef !important;
	// 					  width: 30% !important;
	// 				  }

	// 				  .summary-table td.first {
	// 					  border-top: 2px solid #56734b !important;
	// 				  }

	// 				  .summary-table td.last {
	// 					  border-bottom: 2px solid #56734b !important;
	// 				  }

	// 				  .summary-table td.must-paid {
	// 					  padding: 24px 10px !important;
	// 					  font-size: x-large !important;
	// 					  font-style: italic !important;
	// 				  }

	// 				  .no-italic {
	// 					  font-style: normal !important;
	// 				  }

	// 				  .inv-cut-section {
	// 					  border-image: url(http://i.stack.imgur.com/LKclP.png) 0.5 round !important;
	// 				  }

	// 				  .bold-underline {
	// 					  font-weight: bold !important;
	// 					  text-decoration: underline !important;
	// 				  }

	//         @page {
	//           size: A4 portrait !important;
	//           margin: 0mm !important;
	//         }

	// 		@media print {
	// 			body {
	// 			  transform: scale(.5);
	// 			}
	// 		}
	//     </style>
	//   </head>
	//   <body>
	//   ${this.domReplacer}
	//   </body>
	// </html>`
	// 	;

	constructor(private loading: LoadingService,
		private http: HttpClient,
		private util: UtilService) {
	}

	/**
	 *
	 * @param doc DOC that print
	 * @param css style in header
	 */
	print(srcDoc: string): void {
		const iframe = document.createElement("iframe");
		iframe.onload = this.setPrint.bind(this);
		iframe.style.position = "fixed";
		iframe.style.top = "0";
		iframe.style.bottom = "0";
		iframe.style.left = "0";
		iframe.style.right = "0";
		iframe.style.visibility = "hidden";
		iframe.srcdoc = srcDoc;
		document.body.appendChild(iframe);
	}

	setPrint(e: Event): void {
		let self = e.target as HTMLIFrameElement;
		(self.contentWindow as any).__container__ = self;
		self.contentWindow.onbeforeunload = this.closePrint;
		self.contentWindow.onafterprint = this.closePrint;
		self.contentWindow.focus();

		setTimeout(() => {
			this.loading.setLoading(false);
			self.contentWindow.print();
		}, 1000);
	}

	closePrint(e: Event): void {
		document.body.removeChild((e.target as any).__container__ as HTMLIFrameElement);
	}

	// private getReportTemplate(): string {
	// 	// const template = this.doc.replace("/* -- DEFAULT CSS -- */", this.reportStyle);
	// 	return this.doc;
	// }

  printPaidInvoiceSolidWaste(data: any) {
    let payment = data.payment;
    let invoice = data.invoice;
    let home = data.home;
    let date_of_service = moment(invoice?.createdAt).subtract(1, "day").startOf("months").locale("km").format("MMMM YYYY");

		let dom_v3 = `
		<!DOCTYPE html>
		<html>

		<head>
			<title>Invoice</title>
			<style>
        @font-face {
            font-family: "battambang";
            src: url("/assets/fonts/KhmerOSbattambang.ttf");
            font-display: swap;
        }

        html,
        body {
            margin: 0;
            padding: 0;
            font-family: "SegoeUI", "battambang";
            font-size: 14px;
        }
        * {
            box-sizing: border-box;
            color: #249782b8;
        }
        p {
            margin: 0;
        }
        .pg {
            position: relative;
            background-image: url("/assets/image/invoice-version-3.jpg");
            background-size: contain;
            width: 210mm;
            height: 297mm;
        }
        .pg .ivc-addr {
          width: 362px;
          height: 100px;
          position: absolute;
          top: 259px;
          left: 23px;
          padding: 0 10px;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
        .pg table.main {
          table-layout: fixed;
          position: absolute;
          top: 419px;
          left: 28px;
        }
        .pg .main tr td {
            padding: 0px 5px;
        }
        .pg .main tr td:nth-child(1) {
            width: 415px;
        }
        .pg .main tr td:nth-child(2) {
            width: 77px;
            text-align: center;
        }
        .pg .main tr td:nth-child(3) {
            width: 96px;
            text-align: right;
        }
        .pg .main tr td:nth-child(4) {
          width: 142px;
          text-align: right;
        }
        .pg .receipt {
            position: absolute;
            left: 315px;
            bottom: 34px;
        }
        .pg .receipt p {
            line-height: 32px;
        }
        .pg .total {
            position: absolute;
            right: 33px;
            bottom: 305px;
            font-size: 18px;
        }
        .pg .total p{
            line-height: 21px;
            text-align: right;
        }
        .pg .total p:nth-child(2) {
            margin-top: 48px;
        }
        .pg .ivc-info {
          position: absolute;
          right: 30px;
          top: 260px;
        }
        .pg .ivc-info p {
            line-height: 26px;
            text-align: right;
        }

        .pg .ivc-info-lbl {
          position: absolute;
          right: 217px;
          top: 260px;
          font-size: 14.5px;
          font-weight: bold;
        }

        .pg .ivc-info-lbl label {
            line-height: 26px;
            text-align: left;
        }
        @media print {
            html,
            body {
                print-color-adjust: exact;
                -webkit-print-color-adjust: exact;
                width: 210mm;
                height: 297mm;
            }

            @page {
                margin: 0;
                border: initial;
                border-radius: initial;
                width: initial;
                min-height: initial;
                box-shadow: initial;
                background: initial;
                break-after: avoid;
            }
        }
			</style>
		</head>

		<body>
      <div class="pg">
          <div class="ivc-addr">
              <p>
                  ${ home?.name || home?.name_en || '-'}
              </p>
              <p>
                  ${ home?.address || '-'}
              </p>
          </div>
          <div class="ivc-info">
              <p>
               INV# ${ payment?.payment_number }
              </p>
              <p>
                ${ home?.edc_id || home?.edc_customer_number}
              </p>
              <p>
                  ${transDate(invoice?.createdAt)}
              </p>
              <p>
                  ${moment(invoice?.due_to_date).format('DD/MM/YYYY')}
              </p>
          </div>
          <table class="main">
              <tr>
                  <td>
                      <p>បំណុលយោងពីខែមុន Balance brought forward</p>
                  </td>
                  <td></td>
                  <td></td>
                  <td>${cashFormat((data?.remaining_amount || 0))} $</td>
              </tr>
              <tr>
                  <td>
                      <p>ថ្លៃសេវាសំរាម សំណល់រឹង ប្រចាំខែ${date_of_service}</p>
                  </td>
                  <td>${data?.number_of_inv}</td>
                  <td>${cashFormat(invoice?.amount || 0)} $</td>
                  <td>${cashFormat(data?.number_of_inv * (invoice?.amount || 0))} $</td>
              </tr>
          </table>

          <div class="total">
              <p>${cashFormat((data?.remaining_amount || 0) + (data?.number_of_inv * (invoice?.amount || 0)))} $</p>
              <p><b>${cashFormat((data?.remaining_amount || 0) + (data?.number_of_inv * (invoice?.amount || 0)))} $</b></p>
          </div>

          <div class="receipt">
              <p>
                  ${payment?.payment_number || '-'}
              </p>
              <p>
                  <b>
                      ${home?.edc_id || home?.edc_customer_number}
                  </b>
              </p>
              <p>
                  <b>
                      ${moment(invoice?.due_to_date).format('DD/MM/YYYY')}
                  </b>
              </p>
              <p>
                  <b>
                    ${ cashFormat(absolute(payment?.amount)) } $
                  </b>
              </p>

          </div>
      </div>
		</body>

		</html>
		`;


		this.loading.setLoading(true);
		this.print(dom_v3);
  }

	printInvoiceSolidWaste(data: InvoicePrnt) {
		let invoice_data = data.invoice_data;
		let remaining_amount = data.remaining_amount;
		let number_of_inv = data.number_of_inv;
		let date_of_service = data.date_of_service;


		// Old Invoice UI: version 1
		// --------------------------
		let old_dom = `
		<!Doctype html>
		<html>
		  <head>
			<style>
				  @font-face {
					font-family: 'barcode';
					src: url('/assets/fonts/barcode.ttf');
				}

				.container {
					width: 100%;
					font-size: 11px;
					padding: 18px 20px 6px 20px;
					overflow: hidden;
					position: relative;
				}

				.bar-code-container {
					position: absolute;
					right: 0;
					top: 36px;
				}

				.bar-code-container .text {
					text-align: right;
					font-size: 11px;
					font-weight: normal !important;
					margin-bottom: 2px;
				}

				.bar-code-container .barcode-text {
					font-family: 'barcode', cursive;
					font-size: 40px;
				}

				.summary-table .bar-code-container {
					right: unset;
					left: 32px;
					top: 38px;
				}

				.summary-table .bar-code-container .text {
					text-align: left;
					font-size: larger;
					font-weight: bold;
					margin-bottom: 2px;
				}

				@font-face {
					font-family: 'khmerOSMoulLight';
					src: url('/assets/fonts/KHMEROSMUOLLIGHT.TTF');
				}

				@font-face {
					font-family: "KhmerOSbattambang";
					src: url("/assets/fonts/KhmerOSbattambang.ttf");
					font-display: swap;
				}

				@font-face {
					font-family: "SegoeUI";
					src: url("/assets/fonts/segoeui.ttf");
					font-display: swap;
				}

				* {
					box-sizing: border-box;
					-moz-box-sizing: border-box;
				}

				p {
					margin: 0;
				}

				body {
					font-family: "SegoeUI", "KhmerOSbattambang" !important;
					width: 100%;
					height: 100%;
					margin: 0;
					padding: 0;
				}

				h2 {
					font-family: "SegoeUI", "KhmerOSbattambang" !important;
				}

				h3 {
					font-family: "SegoeUI", "KhmerOSbattambang" !important;
				}

				#header {
					position: relative;
					height: 86px;
					border-bottom: 1px solid #95aa85;
				}

				.legends,
				#header {
					text-align: center;
				}

				.legends h4 {
					margin: 1rem 0;
				}

				#header h2,
				.legends h2 {
					font-weight: 300;
					font-family: 'khmerOSMoulLight' !important;
					line-height: 14px;
					color: #497242;
					font-size: 17px;
				}

				.white {
					color: white;
				}

				.primary {
					color: #497242 !important;
				}

				.secondary {
					color: #86b17c !important;
				}

				.lighter-primary {
					color: #97bb8f !important;
				}

				.darker-primary {
					color: #6ea063 !important;
				}

				.greyish-color {
					color: #f0f5ef !important;
				}

				.dark-text {
					color: #3e6139 !important;
				}

				.bold {
					font-weight: bold;
				}

				.user-name {
					color: black;
					font-weight: bold;
					text-transform: uppercase;
					margin: 0;
					font-size: larger;
				}

				.desc {
					color: black;
					text-transform: uppercase;
					margin: 0;
					font-size: 11px;
				}

				table {
					width: 100%;
				}

				table {
					width: 100%;
					border-collapse: collapse;
				}

				/* table,
				th,
				td {
					border: 1px solid #000000;
					font-family: 'khmerMEF1';
				}

				th,
				td {
					padding: 2 8px;
				} */

				.cut-section {
					background: #95aa85;
					//   border: 1px solid #95aa85;
				}

				.table-right td {
					padding: 2px 10px;
					font-family: "SegoeUI", "KhmerOSbattambang" !important;
				}

				.table-right .tb-label {
					color: #497242;
					font-size: 12px !important;
					text-align: left;
				}

				.tb-desc {
					text-align: right;
					vertical-align: bottom;
					font-size: 11px !important;
					font-family: "SegoeUI", "KhmerOSbattambang" !important;
				}

				.data-table {
					position: relative;
					margin-bottom: 250px;
				}

				.data-table::after {
					content: '';
					background-image: url('/assets/image/logo_1.png');
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
					position: absolute;
					top: 120%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: -1;
					width: 370px;
					height: 370px;
					min-width: 370px;
				}

				.data-table th {
					padding: 2px 20px;
					font-family: "SegoeUI", "KhmerOSbattambang" !important;
					background-color: #86b17c;
					font-size: 12px;
					font-weight: normal;
				}

				.data-table td {
					padding: 10px 20px;
					font-family: "SegoeUI", "KhmerOSbattambang" !important;
					font-size: 12px;
				}

				.summary-table th,
				.summary-table td {
					padding: 8px 10px;
					border: 2px solid #56734b;
					border-collapse: collapse;
					font-size: 11px;
				}

				.summary-table td.label {
					border: 2px solid #f0f5ef;
					width: 30%;
				}

				.summary-table td.first {
					border-top: 2px solid #56734b;
				}

				.summary-table td.last {
					border-bottom: 2px solid #56734b;
				}

				.summary-table td.must-paid {
					padding: 10px 10px;
					font-size: 16px;
					font-style: italic;
				}

				.no-italic {
					font-style: normal !important;
				}

				.break {
					margin-left: -50px;
					width: 120%;
				}

				.inv-cut-section {
					border: 1px dashed #A3A3A3;
					width: 100%;
				}

				.bold-underline {
					font-weight: bold;
					text-decoration: underline;
				}

				@page {
					size: A4 portrait;
					margin: 0;
				}

				@media print {
					html, body {
						width: 210mm;
						height: 297mm;
					}
					.page {
						margin: 0;
						border: initial;
						border-radius: initial;
						width: initial;
						min-height: initial;
						box-shadow: initial;
						background: initial;
						break-after: avoid;
					}
				}
			</style>
		  </head>
		  <body>
				<div class="container">
					<main id="main">
						<header id="header">
							<h2>រដ្ឋបាលរាជធានីភ្នំពេញ</h2>
							<h2>រដ្ឋាករស្វយ័តគ្រប់គ្រងសំរាម និងសំណល់រឹង</h2>
							<h2>រាជធានីភ្នំពេញ</h2>

							<div class="bar-code-container">
								<p class="text">${invoice_data?.invoice_number}</p>
								<p class="barcode-text">${invoice_data?.invoice_number}</p>
							</div>
						</header>
						<div class="legends">
							<h2 style="margin-bottom: 25px;">វិក្កយបត្រ</h2>

							<div style="display: flex; margin-bottom: 25px;">
								<div style="flex: 1">
									<p class="desc primary" style="font-size: 12px; text-align: left;">អាសយដ្ធានទទួលវិក្កយបត្រ</p>
									<p class="user-name" style="font-size: 12px; text-align: left;">${invoice_data?.home.name_en}</p>
									<p class="desc" style="font-size: 12px; text-align: left;">${invoice_data?.home?.address || '-'}</p>
								</div>
								<div style="flex: 1">
									<table class="table-right">
										<tr>
											<td class="tb-label">ថ្ងៃធ្វើវិក្កយបត្រ</td>
											<td class="tb-desc">${transDate(invoice_data?.createdAt)}</td>
										</tr>
										<tr>
											<td class="tb-label">ថ្ងៃផុតកំណត់បង់ប្រាក់</td>
											<td class="tb-desc">${transDate(invoice_data?.due_to_date)}</td>
										</tr>
										<tr>
											<td class="tb-label">លេខសំគាល់អតិថិជន</td>
											<td class="tb-desc bold">${invoice_data?.home?.edc_id || invoice_data?.home?.edc_customer_number}</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</main>

					<table class="data-table">
						<thead>
							<tr>
								<th style="text-align: left;">បរិយាយ</th>
								<th>ចំនួន</th>
								<th>តម្លៃឯកតា</th>
								<th>តម្លៃសរុប</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div>
										<p><span class="bold-underline">ស្ងានភាពបំណុលចាស់</span> <span>(1)</span></p>
										<p>បំណុលសរុបគិតត្រឹមថ្ងៃទី ${startDate(invoice_data.createdAt)}</p>
									</div>
								</td>
								<td></td>
								<td></td>
								<td class="tb-desc"><span class="bold">${cashFormat(remaining_amount[0]?.amount)}</span> $</td>
							</tr>
							<tr>
								<td>
									<div>
										<p><span class="bold-underline">ថ្លៃសេវាសំរាម​ខែថ្មី</span> <span>ប្រភេទអតិថិជន ${invoice_data?.home?.home_type?.code}</span> <span class="bold">(2)</span></p>
										<p>ថ្លៃសំរាម ${startDate(invoice_data?.createdAt)} - ${endDate(invoice_data?.createdAt)}</p>
									</div>
								</td>
								<td class="tb-desc">${number_of_inv}</td>
								<td class="tb-desc">${cashFormat(invoice_data?.amount)} $</td>
								<td class="tb-desc">${cashFormat(number_of_inv * (invoice_data?.amount || 0))} $</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td class="bold-underline" style="text-align: right;">សរុប</td>
								<td></td>
								<td></td>
								<td class="tb-desc"><span class="bold">${cashFormat((remaining_amount[0]?.amount || 0) + (number_of_inv * (invoice_data?.amount || 0)))}</span> $</td>
							</tr>
						</tfoot>
					</table>

					<table class="summary-table" style="background-color: #f0f5ef;">
						<tr>
							<td rowspan="5" style="width: 40%;"></td>
						</tr>
						<tr>
							<td class="label dark-text first" style="background-color: #97bb8f;">បំណុលសរុប</td>
							<td class="tb-desc">${cashFormat((remaining_amount[0]?.amount || 0) + (number_of_inv * (invoice_data?.amount || 0)))} $</td>
						</tr>
						<tr>
							<td class="label must-paid white" style="background-color: #6ea063;">ទឹកប្រាក់ត្រូវទូទាត់</td>
							<td class="tb-desc must-paid no-italic bold" style="font-size: 16px !important;">${cashFormat((remaining_amount[0]?.amount || 0) + (invoice_data?.amount || 0))}$</td>
						</tr>
						<tr>
							<td class="label dark-text" style="background-color: #97bb8f;">ថ្ងៃបង់ប្រាក់</td>
							<td class="tb-desc"></td>
						</tr>
						<tr>
							<td class="label dark-text last" style="background-color: #97bb8f;">ចំនួនទឹកប្រាក់បានបង់</td>
							<td class="tb-desc"></td>
						</tr>
					</table>

					<div class="break">
						<hr class="inv-cut-section" />
					</div>

					<table class="summary-table">
						<tr>
							<td rowspan="5" style="width: 40%; position: relative; text-align: left;">
								<div class="bar-code-container">
									<p class="text"><span class="primary">លេខសំគាល់អតិថិជន ID</span>  <span class="bold">${invoice_data?.home?.edc_id || invoice_data?.home?.edc_customer_number}</span></p>
									<p class="barcode-text">${invoice_data?.invoice_number}</p>
									<p class="text"><span class="primary">អតិថិជន</span>  <span class="bold">${invoice_data?.home?.name_en}</span></p>
								</div>

								<p style="position: absolute; right: 8px; bottom: 8px;">-1-</p>
							</td>
						</tr>
						<tr>
							<td class="label first white" style="font-style: italic; background-color: #97bb8f;">ទឹកប្រាក់ត្រូវទូទាត់</td>
							<td class="tb-desc">${cashFormat(invoice_data?.amount)} $</td>
						</tr>
						<tr>
							<td class="label white" style="font-style: italic; background-color: #6ea063;">ថ្ងៃផុតកំណត់បង់ប្រាក់</td>
							<td class="tb-desc bold">${transDate(invoice_data?.due_to_date)}</td>
						</tr>
						<tr>
							<td class="label dark-text" style="background-color: #97bb8f;">ថ្ងៃបង់ប្រាក់</td>
							<td class="tb-desc"></td>
						</tr>
						<tr>
							<td class="label dark-text last" style="background-color: #97bb8f;">ចំនួនទឹកប្រាក់បានបង់</td>
							<td class="tb-desc"></td>
						</tr>
					</table>
				</div>
		  </body>
		</html>
		`;


		// New Invoice UI: version 2
		// --------------------------
		let dom_v2 = `
		<!DOCTYPE html>
		<html>

		<head>
			<title>Invoice</title>
			<style>
			@font-face {
				font-family: "battambang";
				src: url("/assets/fonts/KhmerOSbattambang.ttf");
				font-display: swap;
			}


			html,
			body {
				margin: 0;
				padding: 0;
				font-family: "SegoeUI", "battambang";
			}
			* {
				box-sizing: border-box;
			}
			.t-r {
				text-align: right;
			}
			.t-c {
				text-align: center;
			}
			p {
				margin: 0;
			}
			.pg {
				position: relative;
				background-image: url("/assets/image/backg.jpg");
				background-size: contain;
				width: 210mm;
				height: 297mm;
			}
			.pg .address {
				width: 355px;
				height: 100px;
				position: absolute;
				top: 268px;
				left: 39px;
				font-size: 13px;
				padding: 10px;

			}
			.pg table.main {
				table-layout: fixed;
				position: absolute;
				top: 412px;
				left: 40px;
				font-size: 13px;
			}
			.pg .main tr td {
				padding: 0px 5px;
			}
			.pg .main tr td:nth-child(1) {
				width: 406px;
			}
			.pg .main tr td:nth-child(2) {
				width: 77px;
				text-align: center;
			}
			.pg .main tr td:nth-child(3) {
				width: 96px;
				text-align: right;
			}
			.pg .main tr td:nth-child(4) {
				width: 125px;
				text-align: right;
			}
			.pg .receipt {
				position: absolute;
				left: 315px;
				bottom: 34px;
			}
			.pg .receipt p {
				line-height: 32px;
			}
			.pg .receipt p:nth-child(3) {
				margin-top: 32px;
			}
			.pg .total {
				position: absolute;
				right: 47px;
				bottom: 296px;
			}
			.pg .total p{
				line-height: 49px;
				text-align: right;
			}
			.pg .total p:nth-child(2) {
				margin-top: 48px;
			}
			.pg .ivc-info {
				position: absolute;
				right: 39px;
				top: 278px;
			}
			.pg .ivc-info p {
				line-height: 26px;
				text-align: left;
			}
			@media print {
				html,
				body {
					print-color-adjust: exact;
					-webkit-print-color-adjust: exact;
					width: 210mm;
					height: 297mm;
				}

				@page {
					margin: 0;
					border: initial;
					border-radius: initial;
					width: initial;
					min-height: initial;
					box-shadow: initial;
					background: initial;
					break-after: avoid;
				}
			}
			</style>
		</head>

		<body>
			<div class="pg">
				<div class="address">
					<p>${invoice_data?.home?.name || invoice_data?.home?.name_en || '-'}</p>
					<p>${invoice_data?.home?.address || '-'}</p>
				</div>
				<div class="ivc-info">
					<p>${invoice_data?.home?.edc_id || invoice_data?.home?.edc_customer_number}</p>
					<p>${transDate(invoice_data?.createdAt)}</p>
					<p>${transDate(invoice_data?.due_to_date)}</p>
				</div>

				<table class="main">
					<tr>
						<td>
							<p>បំណុលយោងពីខែមុន Balance brought forward</p>
						</td>
						<td></td>
						<td></td>
						<td>${cashFormat((remaining_amount[0]?.amount || 0))} $</td>
					</tr>
					<tr>
						<td>
							<p>ថ្លៃសេវាសំរាម​ សំណល់រឹង ប្រចាំខែ${date_of_service}</p>
						</td>
						<td>${number_of_inv}</td>
						<td>${cashFormat(invoice_data?.amount || 0)} $</td>
						<td>${cashFormat(number_of_inv * (invoice_data?.amount || 0))} $</td>
					</tr>
				</table>

				<div class="total">
					<p>${cashFormat((remaining_amount[0]?.amount || 0) + (number_of_inv * (invoice_data?.amount || 0)))} $</p>
					<p><b>${cashFormat((remaining_amount[0]?.amount || 0) + (number_of_inv * (invoice_data?.amount || 0)))} $</b></p>
				</div>
			</div>
		</body>

		</html>
		`;


		// New Invoice UI: version 3
		// --------------------------
		let dom_v3 = `
		<!DOCTYPE html>
		<html>

		<head>
			<title>Invoice</title>
			<style>
        @font-face {
            font-family: "battambang";
            src: url("/assets/fonts/KhmerOSbattambang.ttf");
            font-display: swap;
        }

        html,
        body {
            margin: 0;
            padding: 0;
            font-family: "SegoeUI", "battambang";
            font-size: 14px;
        }
        * {
            box-sizing: border-box;
            color: #249782b8;
        }
        p {
            margin: 0;
        }
        .pg {
            position: relative;
            background-image: url("/assets/image/invoice-version-3.jpg");
            background-size: contain;
            width: 210mm;
            height: 297mm;
        }
        .pg .ivc-addr {
          width: 362px;
          height: 100px;
          position: absolute;
          top: 259px;
          left: 23px;
          padding: 0 10px;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
        .pg table.main {
          table-layout: fixed;
          position: absolute;
          top: 419px;
          left: 28px;
        }
        .pg .main tr td {
            padding: 0px 5px;
        }
        .pg .main tr td:nth-child(1) {
            width: 415px;
        }
        .pg .main tr td:nth-child(2) {
            width: 77px;
            text-align: center;
        }
        .pg .main tr td:nth-child(3) {
            width: 96px;
            text-align: right;
        }
        .pg .main tr td:nth-child(4) {
          width: 142px;
          text-align: right;
        }
        .pg .receipt {
            position: absolute;
            left: 315px;
            bottom: 34px;
        }
        .pg .receipt p {
            line-height: 32px;
        }
        .pg .total {
            position: absolute;
            right: 33px;
            bottom: 305px;
            font-size: 18px;
        }
        .pg .total p{
            line-height: 21px;
            text-align: right;
        }
        .pg .total p:nth-child(2) {
            margin-top: 48px;
        }
        .pg .ivc-info {
          position: absolute;
          right: 30px;
          top: 260px;
        }
        .pg .ivc-info p {
            line-height: 26px;
            text-align: right;
        }

        .pg .ivc-info-lbl {
          position: absolute;
          right: 217px;
          top: 260px;
          font-size: 14.5px;
          font-weight: bold;
        }

        .pg .ivc-info-lbl label {
            line-height: 26px;
            text-align: left;
        }
        @media print {
            html,
            body {
                print-color-adjust: exact;
                -webkit-print-color-adjust: exact;
                width: 210mm;
                height: 297mm;
            }

            @page {
                margin: 0;
                border: initial;
                border-radius: initial;
                width: initial;
                min-height: initial;
                box-shadow: initial;
                background: initial;
                break-after: avoid;
            }
        }
			</style>
		</head>

		<body>
      <div class="pg">
          <div class="ivc-addr">
              <p>
                  ${invoice_data?.home?.name || invoice_data?.home?.name_en || '-'}
              </p>
              <p>
                  ${invoice_data?.home?.address || '-'}
              </p>
          </div>
          <div class="ivc-info">
              <p>
               INV# ${invoice_data?.invoice_number}
              </p>
              <p>
                ${invoice_data?.home?.edc_id || invoice_data?.home?.edc_customer_number}
              </p>
              <p>
                  ${transDate(invoice_data?.createdAt)}
              </p>
              <p>
                  ${moment(invoice_data?.due_to_date).format('DD/MM/YYYY')}
              </p>
          </div>
          <table class="main">
              <tr>
                  <td>
                      <p>បំណុលយោងពីខែមុន Balance brought forward</p>
                  </td>
                  <td></td>
                  <td></td>
                  <td>${cashFormat((remaining_amount[0]?.amount || 0))} $</td>
              </tr>
              <tr>
                  <td>
                      <p>ថ្លៃសេវាសំរាម សំណល់រឹង ប្រចាំខែ${date_of_service}</p>
                  </td>
                  <td>${number_of_inv}</td>
                  <td>${cashFormat(invoice_data?.amount || 0)} $</td>
                  <td>${cashFormat(number_of_inv * (invoice_data?.amount || 0))} $</td>
              </tr>
          </table>

          <div class="total">
              <p>${cashFormat((remaining_amount[0]?.amount || 0) + (number_of_inv * (invoice_data?.amount || 0)))} $</p>
              <p><b>${cashFormat((remaining_amount[0]?.amount || 0) + (number_of_inv * (invoice_data?.amount || 0)))} $</b></p>
          </div>

          <div class="receipt">
              <p>
                  ${invoice_data?.invoice_number || '-'}
              </p>
              <p>
                  <b>
                      ${invoice_data?.home?.edc_id || invoice_data?.home?.edc_customer_number}
                  </b>
              </p>
              <p>
                  <b>
                      ${moment(invoice_data?.due_to_date).format('DD/MM/YYYY')}
                  </b>
              </p>
              <p>
                  <b>
                  ${cashFormat((remaining_amount[0]?.amount || 0) + (number_of_inv * (invoice_data?.amount || 0)))} $
                  </b>
              </p>

          </div>
      </div>
		</body>

		</html>
		`;


		this.loading.setLoading(true);
		this.print(dom_v3);
	}

	printInvoice(data: any) {
		const home = data.homes[0];
		const invoice = home.invoice;
		const address = data.address;
		const date_of_service = moment(invoice?.invoice_date).locale('km').format('MMMM YYYY');
		let amount = home.inv_amount;
		let monthlyAmount = amount * data.number_of_inv;
		let remainingAmount = home.remaining_amount;
		let cashForward = remainingAmount - monthlyAmount;

		console.log(amount, home.home_type.amount, monthlyAmount, cashForward, amount * data.number_of_inv);

		// New Invoice UI: version 3
		// --------------------------
		let dom_v3 = `
		<!DOCTYPE html>
		<html>

		<head>
			<title>Invoice</title>
			<style>
        @font-face {
            font-family: "battambang";
            src: url("/assets/fonts/KhmerOSbattambang.ttf");
            font-display: swap;
        }

        html,
        body {
            margin: 0;
            padding: 0;
            font-family: "SegoeUI", "battambang";
            font-size: 14px;
        }
        * {
            box-sizing: border-box;
            color: #249782b8;
        }
        p {
            margin: 0;
        }
        .pg {
            position: relative;
            background-image: url("/assets/image/invoice-version-3.jpg");
            background-size: contain;
            width: 210mm;
            height: 297mm;
        }

		.pg .addr {
            width: 100%;
            position: absolute;
            top: 145px;
            text-align: center;
            line-height: 23px;
            padding-right: 25px;
        }

        .pg .ivc-addr {
          width: 362px;
          height: 100px;
          position: absolute;
          top: 259px;
          left: 23px;
          padding: 0 10px;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
        .pg table.main {
          table-layout: fixed;
          position: absolute;
          top: 419px;
          left: 28px;
        }
        .pg .main tr td {
            padding: 0px 5px;
        }
        .pg .main tr td:nth-child(1) {
            width: 415px;
        }
        .pg .main tr td:nth-child(2) {
            width: 77px;
            text-align: center;
        }
        .pg .main tr td:nth-child(3) {
            width: 96px;
            text-align: right;
        }
        .pg .main tr td:nth-child(4) {
          width: 142px;
          text-align: right;
        }
        .pg .receipt {
            position: absolute;
            left: 315px;
            bottom: 34px;
        }
        .pg .receipt p {
            line-height: 32px;
        }
        .pg .total {
            position: absolute;
            right: 33px;
            bottom: 305px;
            font-size: 18px;
        }
        .pg .total p{
            line-height: 21px;
            text-align: right;
        }
        .pg .total p:nth-child(2) {
            margin-top: 48px;
        }
        .pg .ivc-info {
          position: absolute;
          right: 30px;
          top: 260px;
        }
        .pg .ivc-info p {
            line-height: 26px;
            text-align: right;
        }

        .pg .ivc-info-lbl {
          position: absolute;
          right: 217px;
          top: 260px;
          font-size: 14.5px;
          font-weight: bold;
        }

        .pg .ivc-info-lbl label {
            line-height: 26px;
            text-align: left;
        }
        @media print {
            html,
            body {
                print-color-adjust: exact;
                -webkit-print-color-adjust: exact;
                width: 210mm;
                height: 297mm;
            }

            @page {
                margin: 0;
                border: initial;
                border-radius: initial;
                width: initial;
                min-height: initial;
                box-shadow: initial;
                background: initial;
                break-after: avoid;
            }
        }
			</style>
		</head>

		<body>
      <div class="pg">
	  		<p class="addr">
                ${address || ''}
            </p>
          <div class="ivc-addr">
              <p>
                  ${home?.name || home?.name_en || ''}
              </p>
              <p>
                  ${home.address || ''}
              </p>
          </div>
          <div class="ivc-info">
              <p>
               INV# ${invoice?.invoice_number}
              </p>
              <p>
                ${home?.edc_id || home?.edc_customer_number}
              </p>
              <p>
                  ${transDate(invoice?.createdAt)}
              </p>
              <p>
                  ${moment(invoice?.due_to_date).format('DD/MM/YYYY')}
              </p>
          </div>
          <table class="main">
              <tr>
                  <td>
                      <p>បំណុលយោងពីខែមុន Balance brought forward</p>
                  </td>
                  <td></td>
                  <td></td>
                  <td>${cashFormat((cashForward || 0))} $</td>
              </tr>
              <tr>
                  <td>
                      <p>ថ្លៃសេវាសំរាម សំណល់រឹង ប្រចាំខែ${date_of_service}</p>
                  </td>
                  <td>${data.number_of_inv}</td>
                  <td>${cashFormat(amount || 0)} $</td>
                  <td>${cashFormat(monthlyAmount || 0)} $</td>
              </tr>
          </table>

          <div class="total">
              <p>${cashFormat(remainingAmount || 0)} $</p>
              <p><b>${cashFormat(remainingAmount || 0)} $</b></p>
          </div>

          <div class="receipt">
              <p>
                  ${invoice?.invoice_number || '-'}
              </p>
              <p>
                  <b>
                      ${home?.edc_id || home?.edc_customer_number}
                  </b>
              </p>
              <p>
                  <b>
                      ${moment(invoice?.due_to_date).format('DD/MM/YYYY')}
                  </b>
              </p>
              <p>
                  <b>
                  ${cashFormat(home.remaining_amount || 0)} $
                  </b>
              </p>

          </div>
      </div>
		</body>

		</html>
		`;


		this.loading.setLoading(true);
		this.print(dom_v3);
	}

	printReport(doc: string): void {
		this.loading.setLoading(true);
		this.print(doc);
	}

	async printUrl(url: string, query?: any) {
		const _url = new URL(url, environment.baseUrl);
		_url.searchParams.append('token', this.util.getToken());

		if (query) {
			Object.keys(query).forEach(k => {
				if (query[k]) {
					_url.searchParams.append(k, query[k]);
				}
			})
		}

		const printWindow = window.open(_url, '_blank');
		await lastValueFrom(timer(100));

		if (printWindow) {
			printWindow.focus();

		} else {
			console.error('Unable to open the print window.');
		}
	}

	async printHTMLFromURL(url: string, query?: any) {
		const _url = new URL(url, environment.baseUrl);
		_url.searchParams.append('token', this.util.getToken());

		if (query) {
			Object.keys(query).forEach(k => {
				if (query[k]) {
					_url.searchParams.append(k, query[k]);
				}
			})
		}

		let data = await axios.get(_url.toString());
		this.printReport(data.data);
	}
}

export interface InvoicePrnt {
	invoice_data: any,
	remaining_amount: any,
	number_of_inv: any,
	date_of_service: any,
}
